let Huangcaihe = [
  {lng: 115.18136038214587, lat: 34.967644907007305},
  {lng: 115.17731800735096, lat: 34.966273107151416},
  {lng: 115.17662631210828, lat: 34.96603646008821},
  {lng: 115.17483868414342, lat: 34.965459629981936},
  {lng: 115.1738191073896, lat: 34.965171213392004},
  {lng: 115.17289834424187, lat: 34.96486430743398},
  {lng: 115.17187876748805, lat: 34.96444277205855},
  {lng: 115.16916588484791, lat: 34.96340741272496},
  {lng: 115.16849215571544, lat: 34.963292782844135},
  {lng: 115.16830800308588, lat: 34.96329648058476},
  {lng: 115.16812385045634, lat: 34.963359342149474},
  {lng: 115.1676971553391, lat: 34.96335564441173},
  {lng: 115.16689766343522, lat: 34.96329648058476},
  {lng: 115.16618800208234, lat: 34.96335564441173},
  {lng: 115.16575681543755, lat: 34.96340371498938},
  {lng: 115.16495283200612, lat: 34.963536833363634},
  {lng: 115.16254537323938, lat: 34.96399165282904},
  {lng: 115.15525113249836, lat: 34.96534130535103},
  {lng: 115.15347697911615, lat: 34.96553728042726},
  {lng: 115.15098418132597, lat: 34.96577392894542},
  {lng: 115.14843748520518, lat: 34.966021669623856},
  {lng: 115.14687892514536, lat: 34.96611780759398},
  {lng: 115.14566172117935, lat: 34.96622134066528},
  {lng: 115.14514070398356, lat: 34.96629529277822},
  {lng: 115.14432324596947, lat: 34.966421011215644},
  {lng: 115.14412112722974, lat: 34.9664579871896},
  {lng: 115.14390553390734, lat: 34.96649496314672},
  {lng: 115.1436315507268, lat: 34.96652454390029},
  {lng: 115.1433351099085, lat: 34.96656521741885},
  {lng: 115.14304316061776, lat: 34.966594798146815},
  {lng: 115.14286799104332, lat: 34.96660958850677},
  {lng: 115.14262994008317, lat: 34.96662068127496},
  {lng: 115.14245027898117, lat: 34.96662068127496},
  {lng: 115.14220773649348, lat: 34.96662437886401},
  {lng: 115.1414980751406, lat: 34.96661328609633},
  {lng: 115.14134087167635, lat: 34.96658740296584},
  {lng: 115.14111180377131, lat: 34.96658740296584},
  {lng: 115.14097705794481, lat: 34.96656521741885},
  {lng: 115.14041561700107, lat: 34.96647647517026},
  {lng: 115.1398137523094, lat: 34.96637664002466},
  {lng: 115.13958019287679, lat: 34.96632487360454},
  {lng: 115.13910858248406, lat: 34.966269409546356},
  {lng: 115.1389064637443, lat: 34.96623613109325},
  {lng: 115.13857409070562, lat: 34.96617696936517},
  {lng: 115.13820129391898, lat: 34.96612520281774},
  {lng: 115.13748714103855, lat: 34.96603646008821},
  {lng: 115.13666519149692, lat: 34.96597360059611},
  {lng: 115.13572197071144, lat: 34.96582939334209},
  {lng: 115.13552883502679, lat: 34.965799812335256},
  {lng: 115.13433858022607, lat: 34.96558534971333},
  {lng: 115.13273061336321, lat: 34.96517860870198},
  {lng: 115.13232188435617, lat: 34.9650972602552},
  {lng: 115.13200747742768, lat: 34.964923470118556},
  {lng: 115.13178290105019, lat: 34.964782958672295},
  {lng: 115.13119451094116, lat: 34.96431705055798},
  {lng: 115.1310283244218, lat: 34.96417653806227},
  {lng: 115.13041747667502, lat: 34.963632974275995},
  {lng: 115.12229679486482, lat: 34.95671423567573},
  {lng: 115.11679018208865, lat: 34.95206196779565},
  {lng: 115.11198424761027, lat: 34.94797159802437},
  {lng: 115.10448339660195, lat: 34.94183935594042},
  {lng: 115.10371085386338, lat: 34.941499073225366},
  {lng: 115.10195915811892, lat: 34.94139550863796},
  {lng: 115.10187831062302, lat: 34.941388111162375},
  {lng: 115.10180644618222, lat: 34.941388111162375},
  {lng: 115.10168966646593, lat: 34.94137331620921},
  {lng: 115.10157288674962, lat: 34.94135112377439},
  {lng: 115.10142915786803, lat: 34.94129934140291},
  {lng: 115.10000983516227, lat: 34.94105522406435},
  {lng: 115.09780000360772, lat: 34.940663155288995},
  {lng: 115.09658729116924, lat: 34.94040793969241},
  {lng: 115.09370373048222, lat: 34.94003806059371},
  {lng: 115.09193406862757, lat: 34.93960899873018},
  {lng: 115.09100881395229, lat: 34.939150343888336},
  {lng: 115.08917627071193, lat: 34.938321799548234},
  {lng: 115.08821508381625, lat: 34.93827741300591},
  {lng: 115.08731677830627, lat: 34.93829220852272},
  {lng: 115.0862477947494, lat: 34.938321799548234},
  {lng: 115.08545728590062, lat: 34.93831440179285},
  {lng: 115.08490931953953, lat: 34.938358788314964},
  {lng: 115.0844197430366, lat: 34.938085071042465},
  {lng: 115.08066033447734, lat: 34.9362208099642},
  {lng: 115.07780821448316, lat: 34.93484478031159},
  {lng: 115.07086880441858, lat: 34.931371311529006},
  {lng: 115.07055439749008, lat: 34.93132692119111},
  {lng: 115.0700782955698, lat: 34.93130842520983},
  {lng: 115.06896888826498, lat: 34.93139720588159},
  {lng: 115.06828168454985, lat: 34.93146379132178},
  {lng: 115.06748668417352, lat: 34.931785620180534},
  {lng: 115.06737439598477, lat: 34.93181151440114},
  {lng: 115.06722168404806, lat: 34.931804116053236},
  {lng: 115.06708244669403, lat: 34.931804116053236},
  {lng: 115.06695219239508, lat: 34.93179301853011},
  {lng: 115.06687583642673, lat: 34.9317893193554},
  {lng: 115.06678600587573, lat: 34.93177452265488},
  {lng: 115.06660634477373, lat: 34.93174862842248},
  {lng: 115.06341736021331, lat: 34.93127143323464},
  {lng: 115.06221812235749, lat: 34.9310716762778},
  {lng: 115.06215973249934, lat: 34.93104948103007},
  {lng: 115.0620115120902, lat: 34.93100139130587},
  {lng: 115.06187676626371, lat: 34.93095700076599},
  {lng: 115.06184532557086, lat: 34.93094220391397},
  {lng: 115.06165668141375, lat: 34.93077573914321},
  {lng: 115.06163422377601, lat: 34.930742446148145},
  {lng: 115.06163871530356, lat: 34.93067955934251},
  {lng: 115.06162973224846, lat: 34.93062407094419},
  {lng: 115.061724054327, lat: 34.93011357590269},
  {lng: 115.06183634251575, lat: 34.92983243233711},
  {lng: 115.06185430862595, lat: 34.92980653748612},
  {lng: 115.0618947323739, lat: 34.929129569169625},
  {lng: 115.06187676626371, lat: 34.929011191617214},
  {lng: 115.06183634251575, lat: 34.928667155877164},
  {lng: 115.0617510034923, lat: 34.92819364194024},
  {lng: 115.06173303738211, lat: 34.928056766304046},
  {lng: 115.0615803254454, lat: 34.92694695533992},
  {lng: 115.06160278308316, lat: 34.92683597341034},
  {lng: 115.06162524072091, lat: 34.926773083583},
  {lng: 115.0616432068311, lat: 34.92672499132926},
  {lng: 115.06165668141375, lat: 34.926658402007895},
  {lng: 115.0617150712719, lat: 34.926595512043214},
  {lng: 115.06218668166464, lat: 34.92604799735431},
  {lng: 115.06224956305034, lat: 34.925870424231015},
  {lng: 115.06233490207379, lat: 34.925707648527315},
  {lng: 115.06281100399409, lat: 34.925075039857575},
  {lng: 115.06288735996243, lat: 34.924964055372946},
  {lng: 115.06344430937861, lat: 34.9242167558993},
  {lng: 115.06357905520511, lat: 34.92388009894206},
  {lng: 115.06365091964591, lat: 34.92376541328671},
  {lng: 115.0636958349214, lat: 34.92368772291215},
  {lng: 115.06368685186631, lat: 34.92362483064981},
  {lng: 115.06370930950406, lat: 34.92354714014094},
  {lng: 115.0635970213153, lat: 34.923432454016},
  {lng: 115.06385752991321, lat: 34.92305509852777},
  {lng: 115.0639383774091, lat: 34.922929312975896},
  {lng: 115.06404168254275, lat: 34.92283682347537},
  {lng: 115.06415846225904, lat: 34.92274433386968},
  {lng: 115.06426625892024, lat: 34.9226666425196},
  {lng: 115.06449083529773, lat: 34.922455765623795},
  {lng: 115.0659191410586, lat: 34.92112389891191},
  {lng: 115.06794481998361, lat: 34.91951453086632},
  {lng: 115.06881617632828, lat: 34.91885227591608},
  {lng: 115.06893744757213, lat: 34.91876348154628},
  {lng: 115.06927431213838, lat: 34.91787183270871},
  {lng: 115.0696920242005, lat: 34.91616250345732},
  {lng: 115.07034329569524, lat: 34.91325064126146},
  {lng: 115.07079244845023, lat: 34.911652217701516},
  {lng: 115.07131346564601, lat: 34.9100056605946},
  {lng: 115.07155600813371, lat: 34.908869701772055},
  {lng: 115.0718434658969, lat: 34.90770412493114},
  {lng: 115.07192880492036, lat: 34.907545013146496},
  {lng: 115.07195575408565, lat: 34.90747470784023},
  {lng: 115.07196473714076, lat: 34.90737850048057},
  {lng: 115.0719243133928, lat: 34.90643492227033},
  {lng: 115.0719512625581, lat: 34.90493997596042},
  {lng: 115.0719512625581, lat: 34.90379284507027},
  {lng: 115.07195575408565, lat: 34.90366702973052},
  {lng: 115.0719871947785, lat: 34.902216438822684},
  {lng: 115.07208151685705, lat: 34.90082503141208},
  {lng: 115.07219829657335, lat: 34.89879708652208},
  {lng: 115.07220727962844, lat: 34.897716482113246},
  {lng: 115.07218931351825, lat: 34.897675774132516},
  {lng: 115.07215338129785, lat: 34.89747223392345},
  {lng: 115.07131346564601, lat: 34.89631019461877},
  {lng: 115.07115177065423, lat: 34.896195470093396},
  {lng: 115.07078346539514, lat: 34.895825389877274},
  {lng: 115.07064871956864, lat: 34.89568105813693},
  {lng: 115.07006482098716, lat: 34.895059318483746},
  {lng: 115.06946295629547, lat: 34.89439316358572},
  {lng: 115.06866346439159, lat: 34.89354935955593},
  {lng: 115.06721719252052, lat: 34.89215780398088},
  {lng: 115.06498939485577, lat: 34.88987056787613},
  {lng: 115.064019224905, lat: 34.88892308555496},
  {lng: 115.0638844790785, lat: 34.88876763818381},
  {lng: 115.06280651246652, lat: 34.88770910771779},
  {lng: 115.06264481747473, lat: 34.88754625565547},
  {lng: 115.06251905470333, lat: 34.8875166461546},
  {lng: 115.05747057773726, lat: 34.88545135690247},
  {lng: 115.05466786454613, lat: 34.88441499130424},
  {lng: 115.05454210177473, lat: 34.88438538066478},
  {lng: 115.05444328816863, lat: 34.88435577001455},
  {lng: 115.05424566095644, lat: 34.88433356201982},
  {lng: 115.05414684735034, lat: 34.884348367350306},
  {lng: 115.05405701679935, lat: 34.88436317267812},
  {lng: 115.05394023708304, lat: 34.88440018598587},
  {lng: 115.05384142347695, lat: 34.88444460193294},
  {lng: 115.05367972848515, lat: 34.884489017855806},
  {lng: 115.05360786404435, lat: 34.88456304434007},
  {lng: 115.05347311821785, lat: 34.88460746019832},
  {lng: 115.05318566045466, lat: 34.88471109710672},
  {lng: 115.05301498240777, lat: 34.8847777207639},
  {lng: 115.05287125352618, lat: 34.8848591496042},
  {lng: 115.05274549075477, lat: 34.884910967914735},
  {lng: 115.05264667714867, lat: 34.88493317575202},
  {lng: 115.05260176187318, lat: 34.88493317575202},
  {lng: 115.05250294826708, lat: 34.88495538358324},
  {lng: 115.0516315919224, lat: 34.88506642264848},
  {lng: 115.051595659702, lat: 34.88507382524746},
  {lng: 115.05131269346636, lat: 34.88514414990408},
  {lng: 115.04921964162811, lat: 34.88554759018166},
  {lng: 115.04696938632561, lat: 34.885988041046524},
  {lng: 115.04671336925527, lat: 34.886006547333224},
  {lng: 115.04648879287778, lat: 34.886006547333224},
  {lng: 115.0460486231779, lat: 34.88603615738321},
  {lng: 115.045698284029, lat: 34.88605466365898},
  {lng: 115.04536591099031, lat: 34.88605466365898},
  {lng: 115.04510540239241, lat: 34.88607687118436},
  {lng: 115.04466523269252, lat: 34.88608057243799},
  {lng: 115.04434184270893, lat: 34.88610277995632},
  {lng: 115.0440768425835, lat: 34.88609537745089},
  {lng: 115.04376692718255, lat: 34.88608057243799},
  {lng: 115.04197480769014, lat: 34.88587330197506},
  {lng: 115.04169633298204, lat: 34.88582518554213},
  {lng: 115.04142684132906, lat: 34.88577706908078},
  {lng: 115.04104955301486, lat: 34.885703043700104},
  {lng: 115.04066328164558, lat: 34.88564382334712},
  {lng: 115.04010184070184, lat: 34.88557720039852},
  {lng: 115.03973353544275, lat: 34.88554018762575},
  {lng: 115.03939217934895, lat: 34.88550317483617},
  {lng: 115.03910472158576, lat: 34.88546616202976},
  {lng: 115.03760455138409, lat: 34.88523668225447},
  {lng: 115.02569751184934, lat: 34.883933817078905},
  {lng: 115.01921623759485, lat: 34.883271272531594},
  {lng: 115.01897369510715, lat: 34.88328237667456},
  {lng: 115.01604072761708, lat: 34.88326386976878},
  {lng: 115.01269004806485, lat: 34.8832897794357},
  {lng: 115.00945614822894, lat: 34.88328237667456},
  {lng: 115.00941123295344, lat: 34.88327867529373},
  {lng: 115.00928547018204, lat: 34.88325646700528},
  {lng: 115.0090743683872, lat: 34.88320464764198},
  {lng: 115.00890818186785, lat: 34.883189842103555},
  {lng: 115.00874648687605, lat: 34.883112112982644},
  {lng: 115.00861623257711, lat: 34.883067696308835},
  {lng: 115.00849945286082, lat: 34.883019578218175},
  {lng: 115.00580004480332, lat: 34.882105329094145},
  {lng: 115.00280419592755, lat: 34.881083726838455},
  {lng: 115.00161843265438, lat: 34.880654353949765},
  {lng: 115.00044165243631, lat: 34.880347128855675},
  {lng: 114.9988022448806, lat: 34.879921453617364},
  {lng: 114.99838453281846, lat: 34.87977709368356},
  {lng: 114.99807012588997, lat: 34.87967345049635},
  {lng: 114.99783207492982, lat: 34.879610524211216},
  {lng: 114.99758953244213, lat: 34.879599419567604},
  {lng: 114.99738741370238, lat: 34.8795883149225},
  {lng: 114.9971538542698, lat: 34.879584613373794},
  {lng: 114.9968843626168, lat: 34.87957350872666},
  {lng: 114.99655198957811, lat: 34.87958091182492},
  {lng: 114.99389300526857, lat: 34.87956980717729},
  {lng: 114.99076241056629, lat: 34.87954019477618},
  {lng: 114.98707486644784, lat: 34.879462462172064},
  {lng: 114.98598342525321, lat: 34.87940323728104},
  {lng: 114.98484257725555, lat: 34.87925147330123},
  {lng: 114.9845775771301, lat: 34.8791996513897},
  {lng: 114.98428562783936, lat: 34.879140426307565},
  {lng: 114.98368825467522, lat: 34.87907009646663},
  {lng: 114.98118198230239, lat: 34.878640712962635},
  {lng: 114.98136613493193, lat: 34.87869253522976},
  {lng: 114.98110562633403, lat: 34.87869623681901},
  {lng: 114.97955604932932, lat: 34.87864441455424},
  {lng: 114.97920571018044, lat: 34.878655519328035},
  {lng: 114.97896316769274, lat: 34.878670325690734},
  {lng: 114.97718003125543, lat: 34.87884430025103},
  {lng: 114.97581909840781, lat: 34.87907749961063},
  {lng: 114.97034841785205, lat: 34.879928856683996},
  {lng: 114.96857875599738, lat: 34.88025829246791},
  {lng: 114.96836316267499, lat: 34.88031011370589},
  {lng: 114.96784663700676, lat: 34.880384143988664},
  {lng: 114.96758163688132, lat: 34.88039154701323},
  {lng: 114.96740646730687, lat: 34.88040635306037},
  {lng: 114.96719536551203, lat: 34.880424860615506},
  {lng: 114.9638357029047, lat: 34.88098378679925},
  {lng: 114.9582617172153, lat: 34.881916555729475},
  {lng: 114.95668519104528, lat: 34.882120134829734},
  {lng: 114.95653247910859, lat: 34.88216825345181},
  {lng: 114.9563528180066, lat: 34.88222377490348},
  {lng: 114.95615519079439, lat: 34.88224968490132},
  {lng: 114.95600697038525, lat: 34.88226819203757},
  {lng: 114.95588120761386, lat: 34.882260789183576},
  {lng: 114.95574646178736, lat: 34.88224968490132},
  {lng: 114.95562968207106, lat: 34.88222007347455},
  {lng: 114.95549942777211, lat: 34.882194163467304},
  {lng: 114.95521646153647, lat: 34.88211273196228},
  {lng: 114.95497841057633, lat: 34.8820683147434},
  {lng: 114.95494247835593, lat: 34.882046106124875},
  {lng: 114.95492002071818, lat: 34.88203500181336},
]

let BeishaRiver = [
  {lng: 114.95799222556231, lat: 34.87619022240583},
  {lng: 114.95818536124695, lat: 34.87623464283027},
  {lng: 114.95871086997029, lat: 34.87640862259275},
  {lng: 114.95879171746618, lat: 34.87641972767135},
  {lng: 114.95893544634778, lat: 34.87641972767135},
  {lng: 114.95921841258343, lat: 34.87641232428579},
  {lng: 114.95937112452012, lat: 34.87638271073678},
  {lng: 114.95955527714966, lat: 34.87636420226318},
  {lng: 114.9597753619996, lat: 34.87631978190939},
  {lng: 114.9600718028179, lat: 34.876271659832135},
  {lng: 114.96346739764562, lat: 34.87576452544332},
  {lng: 114.96663392456828, lat: 34.875309212316985},
  {lng: 114.96782867089657, lat: 34.87506489690737},
  {lng: 114.96912672235848, lat: 34.874805773702555},
  {lng: 114.96975104468791, lat: 34.87469842241911},
  {lng: 114.97062689256015, lat: 34.87472063304108},
  {lng: 114.97347901255432, lat: 34.8746910188771},
  {lng: 114.97371257198691, lat: 34.874676211791076},
  {lng: 114.97401350433276, lat: 34.87463549229062},
  {lng: 114.97415274168681, lat: 34.874609579870636},
  {lng: 114.97430994515105, lat: 34.874542947895726},
  {lng: 114.97440875875715, lat: 34.87449482476882},
  {lng: 114.9763670647689, lat: 34.873702639978916},
  {lng: 114.9764029969893, lat: 34.87368783271324},
  {lng: 114.9765018105954, lat: 34.87366191999184},
  {lng: 114.97662308183925, lat: 34.87363600726219},
  {lng: 114.9768072344688, lat: 34.87361009452431},
  {lng: 114.97694647182284, lat: 34.87360269088341},
  {lng: 114.9785095234102, lat: 34.87351014531525},
  {lng: 114.97896765922029, lat: 34.87346572340519},
  {lng: 114.97920571018044, lat: 34.87345831975117},
  {lng: 114.97987494778536, lat: 34.87336947585032},
  {lng: 114.98001867666696, lat: 34.873336159362516},
  {lng: 114.98333791552633, lat: 34.87250694461013},
  {lng: 114.98497732308203, lat: 34.872092334069},
  {lng: 114.98513452654629, lat: 34.872070122730484},
  {lng: 114.98551181486047, lat: 34.872092334069},
  {lng: 114.98568698443492, lat: 34.87212565106546},
  {lng: 114.98624842537866, lat: 34.87218488124771},
  {lng: 114.98709732408558, lat: 34.87222190008976},
  {lng: 114.98787435835172, lat: 34.87212565106546},
  {lng: 114.9883774094373, lat: 34.87209973784717},
  {lng: 114.99023690184296, lat: 34.871966469737266},
  {lng: 114.9904749528031, lat: 34.87198497921},
  {lng: 114.99115766499068, lat: 34.872158968048275},
  {lng: 114.99185385176092, lat: 34.872462522153086},
  {lng: 114.99196613994967, lat: 34.872518050220606},
  {lng: 114.99291385226269, lat: 34.87293265859527},
  {lng: 114.99344834404113, lat: 34.87297337894685},
  {lng: 114.99367292041863, lat: 34.87297337894685},
  {lng: 114.99392444596143, lat: 34.872928956744126},
  {lng: 114.99413105622871, lat: 34.872836410410635},
  {lng: 114.99432868344091, lat: 34.872788286275686},
  {lng: 114.99450385301536, lat: 34.87274756583158},
  {lng: 114.994786819251, lat: 34.87268463419615},
  {lng: 114.99615224362617, lat: 34.87280679556173},
  {lng: 114.99790393937062, lat: 34.873062223279646},
  {lng: 114.99827673615727, lat: 34.873062223279646},
  {lng: 114.99867199058166, lat: 34.87304371405164},
  {lng: 114.99993860135072, lat: 34.87317327855939},
  {lng: 115.00015868620066, lat: 34.87322880614249},
  {lng: 115.00067072034135, lat: 34.87328063185258},
  {lng: 115.0027368230143, lat: 34.873436108785036},
  {lng: 115.00459182389241, lat: 34.873502741665256},
  {lng: 115.00496462067905, lat: 34.87351014531525},
  {lng: 115.00531495982794, lat: 34.87368783271324},
  {lng: 115.00565182439418, lat: 34.873898835995},
  {lng: 115.00614140089712, lat: 34.87422829616667},
  {lng: 115.00643784171541, lat: 34.87439117486049},
  {lng: 115.00687351988775, lat: 34.87465770292975},
  {lng: 115.00717894376115, lat: 34.87476505426686},
  {lng: 115.00784818136607, lat: 34.87504268637925},
  {lng: 115.00802335094052, lat: 34.87510191444077},
  {lng: 115.00823445273537, lat: 34.87515744070934},
  {lng: 115.00838716467206, lat: 34.87521296694006},
  {lng: 115.009056402277, lat: 34.875309212316985},
  {lng: 115.00942919906363, lat: 34.87550540446393},
  {lng: 115.0108575048245, lat: 34.87616801218454},
  {lng: 115.0113425897999, lat: 34.87633829039307},
  {lng: 115.01233521738841, lat: 34.87691205130155},
  {lng: 115.0126855565373, lat: 34.876997189672664},
  {lng: 115.0133862348351, lat: 34.877248902597266},
  {lng: 115.01383987911763, lat: 34.87737475876788},
  {lng: 115.01421267590428, lat: 34.87747100159068},
  {lng: 115.01457199810827, lat: 34.87754873609529},
  {lng: 115.0151064898867, lat: 34.87766718853113},
  {lng: 115.01537149001214, lat: 34.87775602774495},
  {lng: 115.01563649013758, lat: 34.87785967337197},
  {lng: 115.01675038896995, lat: 34.878381601132375},
  {lng: 115.01718157561474, lat: 34.8785222619425},
  {lng: 115.01756784698404, lat: 34.878618503409484},
  {lng: 115.01780140641664, lat: 34.878637011370856},
  {lng: 115.01821911847877, lat: 34.87859999544389},
  {lng: 115.01843920332871, lat: 34.87855187471368},
  {lng: 115.01879403400515, lat: 34.87851115715053},
  {lng: 115.01915335620915, lat: 34.878451931567795},
  {lng: 115.01952166146825, lat: 34.87839640754488},
  {lng: 115.01997530575078, lat: 34.87837789952882},
  {lng: 115.02046488225372, lat: 34.87838900433895},
  {lng: 115.02084666209547, lat: 34.87838900433895},
  {lng: 115.0211565774964, lat: 34.87837789952882},
  {lng: 115.02621852904512, lat: 34.878481544364554},
  {lng: 115.02684285137457, lat: 34.87851485874803},
  {lng: 115.0271617498306, lat: 34.87851485874803},
  {lng: 115.0274941228693, lat: 34.87851856034534},
  {lng: 115.02766929244375, lat: 34.87852596353948},
  {lng: 115.02788488576614, lat: 34.87851115715053},
  {lng: 115.02797471631713, lat: 34.87847784276554},
  {lng: 115.02991954774623, lat: 34.878625906594536},
  {lng: 115.03024293772982, lat: 34.87866662410032},
  {lng: 115.03264590496902, lat: 34.87872214793905},
  {lng: 115.03468955000422, lat: 34.87880728441825},
  {lng: 115.03541268593975, lat: 34.878873912905114},
  {lng: 115.03615827951303, lat: 34.8790108712903},
  {lng: 115.03756412763614, lat: 34.879262577992264},
  {lng: 115.03790548372994, lat: 34.87924777173722},
  {lng: 115.03888912826336, lat: 34.87912932159988},
  {lng: 115.03932929796325, lat: 34.87909230589664},
  {lng: 115.0395853150336, lat: 34.87909230589664},
  {lng: 115.04094175635366, lat: 34.8791996513897},
  {lng: 115.04130107855765, lat: 34.87920705452193},
  {lng: 115.04166489228919, lat: 34.87919224825678},
  {lng: 115.04241497739002, lat: 34.8791478294452},
  {lng: 115.04251379099613, lat: 34.87909970903864},
  {lng: 115.04328633373471, lat: 34.87911451532061},
  {lng: 115.04424302910283, lat: 34.879170038854014},
  {lng: 115.04928701454136, lat: 34.87915893415036},
  {lng: 115.05103871028582, lat: 34.87913672473851},
  {lng: 115.05154625289896, lat: 34.879096007467716},
  {lng: 115.0519729480162, lat: 34.87907749961063},
  {lng: 115.05304642310062, lat: 34.87900716971535},
  {lng: 115.05462294927062, lat: 34.87871104317432},
  {lng: 115.05506311897052, lat: 34.878670325690734},
  {lng: 115.05520235632457, lat: 34.878625906594536},
  {lng: 115.05743464551686, lat: 34.87807806908289},
  {lng: 115.05848117143599, lat: 34.87774122121605},
  {lng: 115.05943337527656, lat: 34.877382162065985},
  {lng: 115.06059668091198, lat: 34.877097134603474},
  {lng: 115.06175549501985, lat: 34.87679359776762},
  {lng: 115.06405066559785, lat: 34.87619022240583},
  {lng: 115.06520498817817, lat: 34.87591629591992},
  {lng: 115.0659820224443, lat: 34.87566457887749},
  {lng: 115.06697465003283, lat: 34.875394352363315},
  {lng: 115.06735642987456, lat: 34.87530180883048},
  {lng: 115.0679358369285, lat: 34.87511672144942},
  {lng: 115.06866346439159, lat: 34.87495014244691},
  {lng: 115.06878024410788, lat: 34.87489461603707},
  {lng: 115.06933719352406, lat: 34.8747909666374},
  {lng: 115.06970999031071, lat: 34.87475394896269},
  {lng: 115.07005583793205, lat: 34.874687317105845},
  {lng: 115.07028939736465, lat: 34.87462068519451},
  {lng: 115.07054541443499, lat: 34.87457626388999},
  {lng: 115.07087329594613, lat: 34.8745207372253},
  {lng: 115.07111583843383, lat: 34.874454105177705},
  {lng: 115.07133143175622, lat: 34.87439857842975},
  {lng: 115.0717087200704, lat: 34.87431713913093},
  {lng: 115.07203660158156, lat: 34.87426161228964},
  {lng: 115.0724004153131, lat: 34.87425420870793},
  {lng: 115.07305617833538, lat: 34.8742394015425},
  {lng: 115.07337058526387, lat: 34.874220892581945},
  {lng: 115.07387363634946, lat: 34.874191278236296},
  {lng: 115.07404431439635, lat: 34.87414315490167},
  {lng: 115.07440363660035, lat: 34.87406541714721},
  {lng: 115.07530194211033, lat: 34.873906239609},
  {lng: 115.07553999307046, lat: 34.87386181791492},
  {lng: 115.07580050166835, lat: 34.87382479981803},
  {lng: 115.07681558689464, lat: 34.8740950315386},
  {lng: 115.07761058727097, lat: 34.87433194628223},
  {lng: 115.07831126556874, lat: 34.874561456783105},
  {lng: 115.07979796118777, lat: 34.87506489690737},
  {lng: 115.0807277073906, lat: 34.87539805410243},
  {lng: 115.08114092792518, lat: 34.875446176695775},
  {lng: 115.08256923368604, lat: 34.87584226157642},
  {lng: 115.08279381006354, lat: 34.87597552334628},
  {lng: 115.08292406436249, lat: 34.87604585585915},
  {lng: 115.08342262392053, lat: 34.87624944963303},
  {lng: 115.08371008168372, lat: 34.876301273421504},
  {lng: 115.08542135368022, lat: 34.87633458869668},
  {lng: 115.08785127008471, lat: 34.876423429363875},
  {lng: 115.08988593206482, lat: 34.87640862259275},
  {lng: 115.09219457722546, lat: 34.87639011412504},
  {lng: 115.0941798324025, lat: 34.876404920899525},
  {lng: 115.09720263044359, lat: 34.87639381581891},
  {lng: 115.1005173777754, lat: 34.87637530734785},
  {lng: 115.10397136246127, lat: 34.87641602597864},
  {lng: 115.10451034576725, lat: 34.87640862259275},
  {lng: 115.10474390519985, lat: 34.87641972767135},
  {lng: 115.10505382060079, lat: 34.87642713105624},
  {lng: 115.10525593934054, lat: 34.87648635811119},
  {lng: 115.10582187181183, lat: 34.876645530607966},
  {lng: 115.10810805933471, lat: 34.87741917854637},
  {lng: 115.11100060307685, lat: 34.87835198829929},
  {lng: 115.1130038243641, lat: 34.87898496026212},
  {lng: 115.11380331626798, lat: 34.87924407017303},
  {lng: 115.11400543500773, lat: 34.87926998111878},
  {lng: 115.11723933484365, lat: 34.87993255821705},
  {lng: 115.11448153692801, lat: 34.87935511702537},
  {lng: 115.11783221648022, lat: 34.880025096488986},
  {lng: 115.11810619966077, lat: 34.880036201074546},
  {lng: 115.11845204728212, lat: 34.88004360413074},
  {lng: 115.12095831965495, lat: 34.8800991270308},
  {lng: 115.12205425237713, lat: 34.88014354532361},
  {lng: 115.12260221873821, lat: 34.88014354532361},
  {lng: 115.12410238893987, lat: 34.88023608335583},
  {lng: 115.12852205204896, lat: 34.88071727942847},
  {lng: 115.1294158660314, lat: 34.88082832427228},
  {lng: 115.13101934136671, lat: 34.881031906092694},
  {lng: 115.1312529007993, lat: 34.88106521943303},
  {lng: 115.13273061336321, lat: 34.881246591824784},
  {lng: 115.13377713928234, lat: 34.881420560882255},
  {lng: 115.13406459704554, lat: 34.88149829161761},
  {lng: 115.13424874967508, lat: 34.88151679892462},
  {lng: 115.13489552964226, lat: 34.881494590155704},
  {lng: 115.13547044516865, lat: 34.881513097463554},
  {lng: 115.13569502154614, lat: 34.88150939600232},
  {lng: 115.13576239445939, lat: 34.88150199307935},
  {lng: 115.13598697083688, lat: 34.88150939600232},
  {lng: 115.13623400485213, lat: 34.88153530622742},
  {lng: 115.13758595464465, lat: 34.88185363117734},
  {lng: 115.13785095477009, lat: 34.88184622828567},
  {lng: 115.13858756528828, lat: 34.881894347069576},
  {lng: 115.13958019287679, lat: 34.88197948023297},
  {lng: 115.14031231186743, lat: 34.882075717614896},
  {lng: 115.14108036307846, lat: 34.88220156632735},
  {lng: 115.14200561775374, lat: 34.88228299774356},
  {lng: 115.14272875368927, lat: 34.88234592196394},
  {lng: 115.14305214367286, lat: 34.88237183192291},
  {lng: 115.14329019463301, lat: 34.88240514471519},
  {lng: 115.14441307652048, lat: 34.882553201404896},
  {lng: 115.1450104496846, lat: 34.882649438108864},
  {lng: 115.1452799413376, lat: 34.88267904937952},
  {lng: 115.14592222977724, lat: 34.88275677891375},
  {lng: 115.14623214517817, lat: 34.88280489715968},
  {lng: 115.14806468841854, lat: 34.88347484824548},
  {lng: 115.14990172318645, lat: 34.884129988452514},
  {lng: 115.15018918094964, lat: 34.884181807227016},
  {lng: 115.15057096079137, lat: 34.8842003139242},
  {lng: 115.15086291008211, lat: 34.88422252195529},
  {lng: 115.15239002944908, lat: 34.884418692633425},
  {lng: 115.15281672456632, lat: 34.8844668098974},
  {lng: 115.15492325098722, lat: 34.88457784962885},
  {lng: 115.15526011555346, lat: 34.88458895359366},
  {lng: 115.15549367498605, lat: 34.884592654914925},
  {lng: 115.1556149462299, lat: 34.88457784962885},
  {lng: 115.1556778276156, lat: 34.88455194037172},
  {lng: 115.15584850566249, lat: 34.88445570591593},
  {lng: 115.15618537022874, lat: 34.884392783325666},
  {lng: 115.15682765866838, lat: 34.88427804200647},
  {lng: 115.15816164235069, lat: 34.884052260228934},
  {lng: 115.15912282924637, lat: 34.88429654868179},
  {lng: 115.16118444039176, lat: 34.88470369447478},
  {lng: 115.16247800032613, lat: 34.88493687705763},
  {lng: 115.16301698363212, lat: 34.88497389010462},
  {lng: 115.16331791597796, lat: 34.885036812245886},
  {lng: 115.1636278313789, lat: 34.88509603304032},
  {lng: 115.1652941880999, lat: 34.88520707191379},
  {lng: 115.16583766293346, lat: 34.88522927967031},
  {lng: 115.16617452749969, lat: 34.8852403835463},
  {lng: 115.16803401990535, lat: 34.88539213636645},
  {lng: 115.16846520655014, lat: 34.88542174663984},
  {lng: 115.17067054657713, lat: 34.88574005639886},
  {lng: 115.17099842808827, lat: 34.88559200550293},
  {lng: 115.17123198752087, lat: 34.88543655177249},
  {lng: 115.17192368276355, lat: 34.88512934471825},
  {lng: 115.17217969983389, lat: 34.88502570834214},
  {lng: 115.1723324117706, lat: 34.88498499401544},
  {lng: 115.17259292036849, lat: 34.88475551288423},
  {lng: 115.17305105617858, lat: 34.88443719927678},
  {lng: 115.17327114102852, lat: 34.88427063933518},
  {lng: 115.17357207337436, lat: 34.884033753498095},
  {lng: 115.17472639595468, lat: 34.88312321714732},
  {lng: 115.17683292237558, lat: 34.881787005128},
  {lng: 115.1783735163252, lat: 34.88096898085708},
  {lng: 115.17958173723612, lat: 34.88026939702167},
  {lng: 115.17999944929825, lat: 34.880069514822154},
  {lng: 115.18066868690319, lat: 34.87957350872666},
  {lng: 115.18107741591022, lat: 34.8792958920563},
  {lng: 115.18158495852336, lat: 34.87898496026212},
  {lng: 115.1819038569794, lat: 34.87873695428965},
  {lng: 115.18288300998528, lat: 34.8781854159126},
  {lng: 115.18318394233113, lat: 34.87803364966369},
  {lng: 115.18422148519515, lat: 34.87768939834367},
  {lng: 115.18487724821743, lat: 34.87748580816869},
  {lng: 115.18569919775906, lat: 34.87717116780651},
  {lng: 115.18647174049764, lat: 34.87688243793422},
  {lng: 115.18684004575674, lat: 34.876756581002944},
  {lng: 115.18722182559847, lat: 34.87671586254236},
  {lng: 115.18772487668406, lat: 34.87671586254236},
  {lng: 115.18795394458911, lat: 34.87670475750413},
  {lng: 115.18834021595839, lat: 34.87672326590035},
  {lng: 115.18860970761139, lat: 34.87677879106377},
  {lng: 115.18895106370519, lat: 34.876908349631215},
  {lng: 115.18973258949886, lat: 34.87723039432048},
  {lng: 115.18978199630192, lat: 34.8772563059068},
  {lng: 115.19055903056804, lat: 34.87783376197758}, // 中心点
  {lng: 115.1899796235141, lat: 34.87833348027314},
  {lng: 115.18895555523274, lat: 34.87919224825678},
  {lng: 115.18870402968994, lat: 34.87963643504041},
  {lng: 115.18860521608384, lat: 34.88002139496011},
  {lng: 115.18850640247774, lat: 34.88059142842247},
  {lng: 115.18839860581654, lat: 34.881220681507834},
  {lng: 115.18821894471455, lat: 34.88214604486053},
  {lng: 115.18816504638396, lat: 34.882427353235805},
  {lng: 115.18848843636755, lat: 34.882893730769766},
  {lng: 115.18816504638396, lat: 34.882427353235805},
  {lng: 115.18848843636755, lat: 34.882893730769766},
  {lng: 115.18936428423977, lat: 34.88421511927893},
  {lng: 115.18998411504165, lat: 34.88544395433783},
  {lng: 115.19015479308855, lat: 34.885688238615906},
  {lng: 115.19036140335585, lat: 34.88576226401003},
  {lng: 115.19057699667825, lat: 34.88574005639886},
  {lng: 115.19081055611083, lat: 34.88557720039852},
  {lng: 115.19170886162081, lat: 34.88545135690247},
  {lng: 115.1921041160452, lat: 34.88535512350956},
  {lng: 115.1924364890839, lat: 34.885273695165196},
  {lng: 115.1927239468471, lat: 34.88524408483796},
  {lng: 115.19424208315895, lat: 34.885007201832536},
  {lng: 115.19728733883778, lat: 34.88450382315804},
  {lng: 115.19881445820474, lat: 34.88425953532695},
  {lng: 115.20143751029387, lat: 34.88373394387263},
  {lng: 115.20645903809465, lat: 34.88251988867319},
  {lng: 115.20955819210408, lat: 34.8817351848297},
  {lng: 115.20996242958357, lat: 34.88168336449843},
  {lng: 115.21051039594465, lat: 34.881616738309795},
  {lng: 115.21124700646284, lat: 34.88153530622742},
  {lng: 115.21192073559531, lat: 34.88150569454092},
  {lng: 115.21213632891771, lat: 34.88154270914736},
  {lng: 115.21223514252381, lat: 34.88160933539657},
  {lng: 115.212702261389, lat: 34.881875839848355},
  {lng: 115.21515463543123, lat: 34.88322315456123},
  {lng: 115.21616972065752, lat: 34.8839116089691},
  {lng: 115.2167266700737, lat: 34.88436317267812},
  {lng: 115.216753619239, lat: 34.884540836401854},
  {lng: 115.2168254836798, lat: 34.884829539126244},
  {lng: 115.2169692125614, lat: 34.88539953893581},
  {lng: 115.21755311114288, lat: 34.885629018252146},
  {lng: 115.21806514528357, lat: 34.88582888680722},
  {lng: 115.21965514603623, lat: 34.886761600244526},
  {lng: 115.22007734962592, lat: 34.88722055055371},
  {lng: 115.2207510787584, lat: 34.88775352186007},
  {lng: 115.22089480764, lat: 34.88781274067878},
  {lng: 115.22110141790729, lat: 34.88778313127481},
  {lng: 115.2248922671594, lat: 34.8875166461546},
  {lng: 115.22742548869753, lat: 34.88782014302809},
  {lng: 115.22750633619344, lat: 34.88782754537671},
  {lng: 115.22812616699532, lat: 34.88790156882608},
  {lng: 115.23088396491096, lat: 34.88777572892213},
  {lng: 115.23197989763312, lat: 34.88764248645894},
  {lng: 115.23327345756749, lat: 34.887413012816765},
  {lng: 115.23521379746904, lat: 34.88717613612072},
  {lng: 115.23919329087825, lat: 34.88700588056981},
  {lng: 115.23936396892515, lat: 34.88700588056981},
  {lng: 115.23947176558634, lat: 34.88702808783576},
  {lng: 115.23974125723933, lat: 34.88713912407472},
  {lng: 115.24257091959576, lat: 34.888212466578494},
  {lng: 115.24305600457116, lat: 34.88853816771255},
  {lng: 115.24355007260165, lat: 34.8889749012794},
  {lng: 115.24399024230154, lat: 34.889359817057176},
]

let Dongfanghongmaincanal = [
  {lng: 114.79134757040629, lat: 34.92797908002965},
  {lng: 114.79545731811444, lat: 34.929299736599226},
  {lng: 114.79808037020358, lat: 34.930091380393385},
  {lng: 114.81258800418972, lat: 34.93202236703339},
  {lng: 114.81564224292364, lat: 34.930868219613494},
  {lng: 114.81713343007021, lat: 34.93256984148987},
  {lng: 114.81934326162475, lat: 34.9347744986019},
  {lng: 114.83698598184073, lat: 34.933087719338985},
  {lng: 114.88035617186246, lat: 34.92881882772695},
  {lng: 114.88132634181324, lat: 34.9287855339305},
]

let Landongmaincanal = [
  {lng: 114.95263383319528, lat: 35.00325921525593},
  {lng: 114.95185230740161, lat: 35.001263394023546},
  {lng: 114.95100790022222, lat: 34.99897922721928},
  {lng: 114.95040154400299, lat: 34.99705722472239},
  {lng: 114.94973230639805, lat: 34.99482468771477},
  {lng: 114.94914840781657, lat: 34.992784302132335},
  {lng: 114.94872620422687, lat: 34.991239193391976},
  {lng: 114.9476662037251, lat: 34.98827457220359},
  {lng: 114.94653433878253, lat: 34.9842673559181},
  {lng: 114.94556416883175, lat: 34.98040782029023},
  {lng: 114.94215959094895, lat: 34.95746863238512},
  {lng: 114.94194399762654, lat: 34.95610775480873},
  {lng: 114.94172840430414, lat: 34.95557523128911},
  {lng: 114.94108162433696, lat: 34.955397722673375},
  {lng: 114.94057857325137, lat: 34.95504270427805},
  {lng: 114.93993179328419, lat: 34.95474685442991},
  {lng: 114.93906941999461, lat: 34.95445100350417},
  {lng: 114.93878196223142, lat: 34.954332662832144},
  {lng: 114.93813518226423, lat: 34.95421432198771},
  {lng: 114.93756026673785, lat: 34.95397763978159},
  {lng: 114.93720094453386, lat: 34.95385929841991},
  {lng: 114.93669789344827, lat: 34.95368178605411},
  {lng: 114.93605111348108, lat: 34.95344510229626},
  {lng: 114.93516179102622, lat: 34.95317883224433},
  {lng: 114.93364365471434, lat: 34.95275723621035},
  {lng: 114.93284416281047, lat: 34.95256492852079},
  {lng: 114.9318290775842, lat: 34.95229125910072},
  {lng: 114.93100263651502, lat: 34.95203238177368},
  {lng: 114.93005941572953, lat: 34.95178829668111},
  {lng: 114.9231873785782, lat: 34.94930302489793},
  {lng: 114.92002534318308, lat: 34.94717273142679},
  {lng: 114.91700703666956, lat: 34.945870857918734},
  {lng: 114.91377313683364, lat: 34.944154720058926},
  {lng: 114.91104228808331, lat: 34.94279361943169},
  {lng: 114.90694601495781, lat: 34.94072233560271},
  {lng: 114.90378397956268, lat: 34.93906527052826},
  {lng: 114.89983143531877, lat: 34.93717144049987},
  {lng: 114.89580702663407, lat: 34.935100013377806},
  {lng: 114.89192634683097, lat: 34.933028533466064},
  {lng: 114.88826126035026, lat: 34.931608059588434},
  {lng: 114.88603346268552, lat: 34.93036512458462},
  {lng: 114.88529685216733, lat: 34.93014316990566},
  {lng: 114.88450634331855, lat: 34.92980283822101},
  {lng: 114.88398532612277, lat: 34.92966966456529},
  {lng: 114.88314091894338, lat: 34.92949209935165},
  {lng: 114.8825480373068, lat: 34.929344128044036},
  {lng: 114.88172159623763, lat: 34.929196156467114},
  {lng: 114.87933210358108, lat: 34.92810115842752},
  {lng: 114.87209625269821, lat: 34.925215619987284},
  {lng: 114.86742506404633, lat: 34.923351063764684},
  {lng: 114.86243048541084, lat: 34.92130888170406},
  {lng: 114.86049014550929, lat: 34.920657740409574},
  {lng: 114.85405827805785, lat: 34.917993926280666},
  {lng: 114.85086031044233, lat: 34.91689877738742},
  {lng: 114.84924336052437, lat: 34.9160996053754},
  {lng: 114.848129461692, lat: 34.91571481605067},
  {lng: 114.84715929174122, lat: 34.91568521679644},
  {lng: 114.84651251177404, lat: 34.915596418969145},
  {lng: 114.84615318957005, lat: 34.91538922366182},
  {lng: 114.84446437521129, lat: 34.91399804150174},
  {lng: 114.84365140872475, lat: 34.91328764143492},
  {lng: 114.84214225546799, lat: 34.91163001715317},
  {lng: 114.84092055997442, lat: 34.91044597913008},
  {lng: 114.83941140671766, lat: 34.90890670393799},
  {lng: 114.83804598234249, lat: 34.907485808682104},
  {lng: 114.83675242240813, lat: 34.906183299566266},
  {lng: 114.83553072691456, lat: 34.904999182277194},
  {lng: 114.83430903142099, lat: 34.90381504775709},
  {lng: 114.83208123375624, lat: 34.902453271757444},
  {lng: 114.83057208049948, lat: 34.90115068208723},
  {lng: 114.8299612327527, lat: 34.90041056503189},
  {lng: 114.8298175038711, lat: 34.90023293593709},
  {lng: 114.8296558088793, lat: 34.900129318786114},
  {lng: 114.82836224894493, lat: 34.89885629730798},
  {lng: 114.82791309618995, lat: 34.89854544020148},
  {lng: 114.82708665512077, lat: 34.89777569368524},
  {lng: 114.82923360528962, lat: 34.899596428497276},
  {lng: 114.82856885921223, lat: 34.89905613339238},
  {lng: 114.82832631672453, lat: 34.89888590268478},
  {lng: 114.82799394368584, lat: 34.89861205253854},
  {lng: 114.82765258759206, lat: 34.89832339868425},
  {lng: 114.82740106204926, lat: 34.89812356080083},
  {lng: 114.82714055345136, lat: 34.89783490521416},
  {lng: 114.82689801096367, lat: 34.89754624860382},
  {lng: 114.82670936680657, lat: 34.897346408812375},
  {lng: 114.82644885820868, lat: 34.89708735650064},
  {lng: 114.82620631572098, lat: 34.89683570489408},
  {lng: 114.8257930951864, lat: 34.89641381663173},
  {lng: 114.8256224171395, lat: 34.89619176929957},
  {lng: 114.8255146204783, lat: 34.89613255657537},
  {lng: 114.8252002135498, lat: 34.895784680950776},
  {lng: 114.82490377273152, lat: 34.89546641046006},
  {lng: 114.82465224718872, lat: 34.89525176221717},
  {lng: 114.82428394192964, lat: 34.89497049814564},
  {lng: 114.82319699226255, lat: 34.8941933160533},
  {lng: 114.82242444952398, lat: 34.893430930220234},
  {lng: 114.82226275453218, lat: 34.89319407103204},
  {lng: 114.82203817815468, lat: 34.8928831923017},
  {lng: 114.8212117370855, lat: 34.891943147014004},
  {lng: 114.81963071938794, lat: 34.89022587090399},
  {lng: 114.81826529501278, lat: 34.88921917496473},
  {lng: 114.81711546396001, lat: 34.888212466578494},
  {lng: 114.81567817514404, lat: 34.88690964901399},
  {lng: 114.81416902188728, lat: 34.885606810604635},
  {lng: 114.8133066485977, lat: 34.884303951350745},
  {lng: 114.81265986863052, lat: 34.88335640425567},
  {lng: 114.81172563090014, lat: 34.88098748828436},
  {lng: 114.81151003757775, lat: 34.8800991270308},
  {lng: 114.81159986812874, lat: 34.87906269332194},
  {lng: 114.8116133427114, lat: 34.878111383631385},

]
let Lancaitrunkcanal = [
  {lng: 114.75441373936354, lat: 34.88140205355338},
  {lng: 114.75448560380434, lat: 34.879210756087794},
  {lng: 114.75398255271875, lat: 34.8777301163176},
  {lng: 114.75380289161676, lat: 34.876101381484304},
  {lng: 114.75355136607396, lat: 34.874591070994185},
  {lng: 114.75294051832718, lat: 34.871807288098054},
  {lng: 114.75243746724159, lat: 34.86917149109576},
  {lng: 114.74992221181365, lat: 34.864106967673045},
  {lng: 114.74967068627086, lat: 34.86330727730749},
  {lng: 114.74961678794027, lat: 34.86322582692255},


  {lng: 114.74954492349947, lat: 34.8630259029053},
  {lng: 114.74951797433417, lat: 34.86285559687415},
  {lng: 114.74947305905867, lat: 34.86275933678607},
  {lng: 114.74946407600356, lat: 34.86271861132997},
  {lng: 114.74941916072807, lat: 34.86250387677036},
  {lng: 114.74938772003522, lat: 34.8624594488598},
  {lng: 114.74937873698012, lat: 34.862422425582494},
  {lng: 114.74935178781482, lat: 34.86238540228838},
  {lng: 114.74933831323217, lat: 34.86233727198089},
  {lng: 114.74931136406687, lat: 34.86227433230513},
  {lng: 114.74922153351588, lat: 34.861837455569066},
]
let Lanqimaincanal = [
  {lng: 114.74937873698012, lat: 34.862422425582494},
  {lng: 114.74985034737286, lat: 34.860937778303736},
  {lng: 114.75028153401765, lat: 34.85773884537797},
  {lng: 114.74956288960966, lat: 34.853828867982195},
  {lng: 114.74949102516887, lat: 34.85240701152541},
  {lng: 114.74949102516887, lat: 34.851577583807774},
  {lng: 114.74920356740567, lat: 34.84991870305981},
  {lng: 114.74927543184647, lat: 34.848615273081336},
  {lng: 114.74934729628727, lat: 34.84831903608962},
  {lng: 114.74934729628727, lat: 34.84760806291867},
  {lng: 114.74992221181365, lat: 34.844349356572174},
  {lng: 114.74992221181365, lat: 34.842394070262465},
  {lng: 114.75035339845844, lat: 34.8400239634065},
  {lng: 114.75056899178084, lat: 34.83753527708812},
  {lng: 114.75064085622164, lat: 34.83629090545687},
  {lng: 114.75049712734004, lat: 34.83457245628542},
  {lng: 114.75035339845844, lat: 34.83303174694439},
  {lng: 114.75020966957685, lat: 34.83190582556522},
  {lng: 114.74941916072807, lat: 34.829772458238914},
  {lng: 114.74956288960966, lat: 34.82846870630387},
  {lng: 114.74941916072807, lat: 34.82592040374208},
  {lng: 114.74925746573628, lat: 34.8243869391437},
  {lng: 114.74889814353229, lat: 34.82263860619108},
  {lng: 114.74868255020989, lat: 34.82133474028862},
  {lng: 114.7482872957855, lat: 34.81979378100604},
  {lng: 114.74778424469991, lat: 34.817600827258076},
  {lng: 114.74659848142674, lat: 34.814400194440445},
  {lng: 114.74641882032475, lat: 34.81297765069051},
  {lng: 114.74638288810435, lat: 34.81188108963445},
  {lng: 114.74633797282884, lat: 34.81123648268152},

  {lng: 114.74668831197773, lat: 34.81099197526477},
  {lng: 114.74668382045019, lat: 34.80973978944294},
  {lng: 114.74510729428017, lat: 34.80230037248342},
  {lng: 114.7441730565498, lat: 34.798032034549124},
  {lng: 114.74387661573151, lat: 34.795838496359764},
  {lng: 114.74335110700817, lat: 34.79279634677175},
  {lng: 114.74335559853571, lat: 34.792766702856795},
  {lng: 114.74339153075611, lat: 34.79272964794798},
  {lng: 114.74348136130712, lat: 34.79267406555326},
  {lng: 114.74361610713362, lat: 34.7925962501371},
  {lng: 114.7444874634783, lat: 34.792073773281004},
  {lng: 114.74508034511487, lat: 34.79172545352098},
  {lng: 114.74567771827901, lat: 34.791377132276224},
  {lng: 114.74803577024271, lat: 34.78999865491338},
  {lng: 114.75033543234825, lat: 34.78867944512539},
  {lng: 114.7504028052615, lat: 34.78863127133124},
  {lng: 114.75042526289924, lat: 34.788583097508706},
  {lng: 114.75048814428494, lat: 34.78853492365775},
  {lng: 114.75053305956044, lat: 34.78849786683002},
  {lng: 114.75212306031311, lat: 34.787178632815824},


  {lng: 114.7521769586437, lat: 34.78712304664545},
  {lng: 114.75385678994736, lat: 34.78588902392745},
  {lng: 114.75464729879614, lat: 34.78530350512944},
  {lng: 114.75621933343861, lat: 34.784102807892275},
  {lng: 114.75776441891577, lat: 34.78288356332852},
  {lng: 114.76068840335074, lat: 34.780663676224776},
  {lng: 114.76117797985368, lat: 34.78021524429924},
  {lng: 114.7625209465911, lat: 34.77885140316097},
  {lng: 114.76385043874586, lat: 34.77748383308763},


  {lng: 114.76423221858761, lat: 34.77708727054947},
  {lng: 114.76538654116793, lat: 34.77587533386201},
  {lng: 114.76664866040944, lat: 34.77457072138776},
  {lng: 114.7683284917131, lat: 34.77280649641286},
  {lng: 114.76981518733211, lat: 34.77123867631019},
  {lng: 114.76992298399331, lat: 34.77111636283963},
  {lng: 114.77025086550447, lat: 34.770667878549055},
  {lng: 114.77033620452791, lat: 34.770549270722135},
  {lng: 114.77151747627353, lat: 34.768581096983795},
  {lng: 114.77252806997225, lat: 34.76691682272112},
  {lng: 114.77410908766981, lat: 34.76424427582604},
  {lng: 114.77557332565108, lat: 34.761794055555306},
  {lng: 114.77716781793129, lat: 34.75912504830271},
  {lng: 114.77843892022791, lat: 34.7569971940309},
  {lng: 114.77893747978595, lat: 34.756333618400106},

]


let Landongmaincanal2 = [
  {lng: 114.94825459383414, lat: 34.98992324025218},
  {lng: 114.94840281424328, lat: 34.9900230464351},
  {lng: 114.94915739087166, lat: 34.99015612115465},
  {lng: 114.94935501808386, lat: 34.99017090722112},
  {lng: 114.94944484863485, lat: 34.9901856932849},
  {lng: 114.94963349279196, lat: 34.99021526540437},
  {lng: 114.949862560697, lat: 34.99023374797357},
  {lng: 114.95022188290099, lat: 34.9902670165875},
  {lng: 114.95045095080603, lat: 34.99028549914491},
  {lng: 114.95100340869467, lat: 34.990340946791854},
  {lng: 114.95120552743442, lat: 34.990355732824646},
  {lng: 114.95138518853642, lat: 34.990374215361825},
  {lng: 114.9519690871179, lat: 34.990414876928824},
  {lng: 114.95348722342976, lat: 34.99048511049653},
  {lng: 114.95838298845914, lat: 34.99061079146593},
  {lng: 114.95946095507111, lat: 34.99050728950528},
  {lng: 114.96113180331967, lat: 34.99021156889002},
  {lng: 114.96156298996446, lat: 34.9900045638179},
  {lng: 114.96195824438885, lat: 34.98985670272866},
  {lng: 114.96280714309579, lat: 34.98964230367054},
  {lng: 114.96290595670187, lat: 34.98958315900307},
  {lng: 114.97258519857189, lat: 34.98703620084903},
  {lng: 114.97265706301269, lat: 34.987006627570665},
  {lng: 114.97405841960826, lat: 34.98653715033247},
  {lng: 114.97408536877356, lat: 34.98649279013798},
  {lng: 114.97408087724601, lat: 34.98645582329072},
  {lng: 114.97405841960826, lat: 34.98639297961171},
  {lng: 114.97405841960826, lat: 34.98638558623451},
  {lng: 114.9741257925215, lat: 34.98637449616744},
  {lng: 114.97481299623664, lat: 34.98627838218937},
  {lng: 114.97497019970089, lat: 34.98624880863476},
  {lng: 114.97501960650393, lat: 34.986237718549006},
  {lng: 114.97507350483453, lat: 34.986215538372925},
  {lng: 114.97511842011004, lat: 34.98620814497956},
  {lng: 114.97518130149572, lat: 34.986185964795396},
  {lng: 114.97521723371612, lat: 34.98617857139932},
  {lng: 114.97527113204673, lat: 34.98615639120708},
  {lng: 114.97529808121203, lat: 34.98614899780832},
  {lng: 114.97537443718038, lat: 34.986126817608},
  {lng: 114.97548223384157, lat: 34.98609354729614},
  {lng: 114.97556757286502, lat: 34.98607136708065},
  {lng: 114.97572477632927, lat: 34.98600112969161},
  {lng: 114.97578765771496, lat: 34.985967859328206},
  {lng: 114.97590443743127, lat: 34.985849564592264},
  {lng: 114.97589545437616, lat: 34.985827384310085},
  {lng: 114.98030613443015, lat: 34.980137941679125},
  {lng: 114.99089266486524, lat: 34.974344577543704},
  {lng: 114.99321927613609, lat: 34.974292816204446},
  {lng: 114.99506080243154, lat: 34.97332413362322},
]
let SimingRiver = [
  {lng: 115.08490931953953, lat: 34.93836618606629},
  {lng: 115.0848868619018, lat: 34.93848085112575},
  {lng: 115.08321152212568, lat: 34.938610311482336},


  {lng: 115.08083999557934, lat: 34.93882854418805},
  {lng: 115.07926796093687, lat: 34.93890622009443},
  {lng: 115.07757465505057, lat: 34.93898759477387},
  {lng: 115.07746685838937, lat: 34.93900608900785},
  {lng: 115.07737702783837, lat: 34.939009787854125},
  {lng: 115.07731414645268, lat: 34.939024583237604},
  {lng: 115.07719287520882, lat: 34.93905047515219},
  {lng: 115.07684702758749, lat: 34.939124452005565},
  {lng: 115.07591728138466, lat: 34.93933158683679},
  {lng: 115.07581397625101, lat: 34.93933898449957},
  {lng: 115.07572863722756, lat: 34.9393426833307},
  {lng: 115.07565228125921, lat: 34.939346382161666},
  {lng: 115.07555346765312, lat: 34.93935008099246},
  {lng: 115.07543219640927, lat: 34.9393537798231},
  {lng: 115.07526151836237, lat: 34.93935008099246},
  {lng: 115.07516719628383, lat: 34.9393537798231},
  {lng: 115.07509533184303, lat: 34.939346382161666},
  {lng: 115.07502795892978, lat: 34.93933158683679},
  {lng: 115.07442609423809, lat: 34.939246513666426},
  {lng: 115.07390507704231, lat: 34.93916513924623},
  {lng: 115.07374338205051, lat: 34.939154042728056},
  {lng: 115.07361312775156, lat: 34.939124452005565},
  {lng: 115.07354126331076, lat: 34.93910965664028},
  {lng: 115.07345592428732, lat: 34.939094861272295},
  {lng: 115.07337956831897, lat: 34.93906527052826},
  {lng: 115.07329872082308, lat: 34.939043077463154},
  {lng: 115.07314151735882, lat: 34.938969100535715},
  {lng: 115.07281363584768, lat: 34.93886553272416},
  {lng: 115.07267439849363, lat: 34.9388063510583},
  {lng: 115.0716009234092, lat: 34.93829220852272},
  {lng: 115.07150660133067, lat: 34.93826631636655},
  {lng: 115.07139431314192, lat: 34.93823302643937},
  {lng: 115.07115177065423, lat: 34.93815904877459},
  {lng: 115.07067566873394, lat: 34.93814425323355},


  {lng: 115.06920244769758, lat: 34.938110963256364},
  {lng: 115.06865448133648, lat: 34.93814055434787},
  {lng: 115.06744626042557, lat: 34.93826631636655},
  {lng: 115.06581583592495, lat: 34.93842536805267},
  {lng: 115.06427075044779, lat: 34.93866949329095},
  {lng: 115.06382608922036, lat: 34.93871387961913},
  {lng: 115.06320625841848, lat: 34.938747169349355},
  {lng: 115.06299066509608, lat: 34.93875826592306},
  {lng: 115.06266727511249, lat: 34.93875826592306},
  {lng: 115.06240676651458, lat: 34.93877306135232},
  {lng: 115.0599409178897, lat: 34.9388766292817},
  {lng: 115.05960405332345, lat: 34.93892101549676},
  {lng: 115.05941990069391, lat: 34.93894320859519},
  {lng: 115.05492388161647, lat: 34.93956461289087},
  {lng: 115.05291616880167, lat: 34.939856819220786},
  {lng: 115.05301498240777, lat: 34.93984942160515},
  {lng: 115.05278591450272, lat: 34.93984202398883},
  {lng: 115.05255684659768, lat: 34.93984572279708},
  {lng: 115.05235921938548, lat: 34.93984942160515},
  {lng: 115.05202235481924, lat: 34.939882710870236},
  {lng: 115.05187862593765, lat: 34.939904903706065},
  {lng: 115.04846057347218, lat: 34.94024149430632},
  {lng: 115.04525811432912, lat: 34.94056328841188},
  {lng: 115.04288209625521, lat: 34.94088878000398},
  {lng: 115.03953590823055, lat: 34.94151016942333},
  {lng: 115.03598760146613, lat: 34.942216624241475},
  {lng: 115.03573607592334, lat: 34.94224991253688},
  {lng: 115.03549353343566, lat: 34.94230169430264},
  {lng: 115.0352015841449, lat: 34.94233498256321},
  {lng: 115.03499048235007, lat: 34.94234977734131},
  {lng: 115.03471200764197, lat: 34.94235717472935},
  {lng: 115.03435268543798, lat: 34.94240895642676},
  {lng: 115.03410565142273, lat: 34.94240895642676},
  {lng: 115.03385412587994, lat: 34.942386764274765},
  {lng: 115.03302319328321, lat: 34.942394161659436},


  {lng: 115.03221471832423, lat: 34.94240895642676},
  {lng: 115.03165776890805, lat: 34.94245334071256},
  {lng: 115.03144217558565, lat: 34.942475532846366},
  {lng: 115.03024742925737, lat: 34.94268265913636},
  {lng: 115.0292413270862, lat: 34.94286019553615},
  {lng: 115.02900776765361, lat: 34.9429045795755},
  {lng: 115.02876522516591, lat: 34.94294896359059},
  {lng: 115.02847776740272, lat: 34.94299334758145},
  {lng: 115.02812742825382, lat: 34.943030334221966},
  {lng: 115.02782200438044, lat: 34.94305992352228},
  {lng: 115.02675302082356, lat: 34.94324485640505},
  {lng: 115.02603437641558, lat: 34.943318829440294},
  {lng: 115.02575590170748, lat: 34.94334841863555},
  {lng: 115.0252528506219, lat: 34.943481569880845},
  {lng: 115.02497437591381, lat: 34.94354074814206},
  {lng: 115.02473183342612, lat: 34.94359992636016},
  {lng: 115.02446234177312, lat: 34.943659104535165},
  {lng: 115.02359098542844, lat: 34.94382184429421},
  {lng: 115.02332149377544, lat: 34.94387362505829},
  {lng: 115.02027623809663, lat: 34.944347047656976},
  {lng: 115.01650335495472, lat: 34.94492402771975},

  {lng: 115.0131706415127, lat: 34.94547881237789},
  {lng: 115.01208818337318, lat: 34.94569332809686},
  {lng: 115.01145936951619, lat: 34.94579688720676},
  {lng: 115.01125725077644, lat: 34.94583017403548},
  {lng: 115.0111269964775, lat: 34.94582647549962},
  {lng: 115.01083504718676, lat: 34.94580058574396},
  {lng: 115.01062394539191, lat: 34.9457931886694},
  {lng: 115.00848148675061, lat: 34.94571182080473},
  {lng: 115.00633453658176, lat: 34.94562675431365},
  {lng: 115.00552157009524, lat: 34.94571921788671},
  {lng: 115.0029613993918, lat: 34.945870857918734},


  {lng: 114.99953885539878, lat: 34.94611496079046},
  {lng: 115.00030690660981, lat: 34.94605578440405},
  {lng: 115.00022605911391, lat: 34.946070578504695},
  {lng: 115.00008682175987, lat: 34.9460927696506},
  {lng: 115.00003292342927, lat: 34.946107563744505},
  {lng: 114.99993860135072, lat: 34.9461445489675},
  {lng: 114.99985775385483, lat: 34.94620372528926},
  {lng: 114.99976343177627, lat: 34.9462370119513},
  {lng: 114.99839351587356, lat: 34.946832471046214},
  {lng: 114.99833063448786, lat: 34.94690274232852},
  {lng: 114.99824080393687, lat: 34.94696191809807},
  {lng: 114.99818690560626, lat: 34.94700999837907},
  {lng: 114.99816444796852, lat: 34.94705807863165},
  {lng: 114.99806114283487, lat: 34.947220811583065},
  {lng: 114.99796232922877, lat: 34.94733546414856},
  {lng: 114.99788148173288, lat: 34.947472307321405},
  {lng: 114.99776919354413, lat: 34.94762024562735},
  {lng: 114.99765241382782, lat: 34.94773489762918},
  {lng: 114.99755809174928, lat: 34.94781626346781},
  {lng: 114.99604444696497, lat: 34.94930672327554},
  {lng: 114.99585580280787, lat: 34.949547117457904},
  {lng: 114.99574351461912, lat: 34.94965437001749},
  {lng: 114.99562224337528, lat: 34.94977641586132},
  {lng: 114.99518656520294, lat: 34.95019063069145},
  {lng: 114.99384359846552, lat: 34.951100416565254},
  {lng: 114.99376275096962, lat: 34.95117068415603},
  {lng: 114.99364147972578, lat: 34.95127793457215},
  {lng: 114.99357410681253, lat: 34.95136299514631},
  {lng: 114.99350224237173, lat: 34.95151462464457},
  {lng: 114.99338097112788, lat: 34.951891848022065},
  {lng: 114.99328215752179, lat: 34.95267587532034},
  {lng: 114.99332258126974, lat: 34.952764632650855},
  {lng: 114.99339444571054, lat: 34.95282380415066},
  {lng: 114.99353368306458, lat: 34.95301611122734},
  {lng: 114.99360105597783, lat: 34.95311596279913},
  {lng: 114.99367292041863, lat: 34.95320102144866},
  {lng: 114.99373580180432, lat: 34.95328608000911},
  {lng: 114.99376724249717, lat: 34.95335264751603},
  {lng: 114.99378970013493, lat: 34.95343770591771},
  {lng: 114.99380317471757, lat: 34.95362631336024},
  {lng: 114.99381664930023, lat: 34.95372616418192},


  {lng: 114.99385707304818, lat: 34.9550796854333},
  {lng: 114.99377173402472, lat: 34.955316364428164},
  {lng: 114.99373580180432, lat: 34.955386628372004},
  {lng: 114.99355164917478, lat: 34.95551606179364},
  {lng: 114.99343037793093, lat: 34.95555674082641},
  {lng: 114.99020995267766, lat: 34.95662178461661},


  {lng: 114.98893885038105, lat: 34.957043360591996},
  {lng: 114.9886918163658, lat: 34.95713211315008},
  {lng: 114.98844478235056, lat: 34.95716169731457},
  {lng: 114.9883414772169, lat: 34.957198677505005},
  {lng: 114.98773512099767, lat: 34.95737988019478},
  {lng: 114.98754198531303, lat: 34.957427954309914},
  {lng: 114.98739376490389, lat: 34.95746493437913},
  {lng: 114.98690867992849, lat: 34.95757587448571},
  {lng: 114.9867649510469, lat: 34.95761655248684},
  {lng: 114.98658978147245, lat: 34.95769421043239},
  {lng: 114.98645952717351, lat: 34.95772009639773},
  {lng: 114.98371969536807, lat: 34.958352450988656},
  {lng: 114.98244859307145, lat: 34.95869636106753},
  {lng: 114.98229588113476, lat: 34.958733340559185},
  {lng: 114.98212520308786, lat: 34.95877401798056},
  {lng: 114.98199045726136, lat: 34.95879250771082},
  {lng: 114.98183774532467, lat: 34.958796205656355},
  {lng: 114.98162664352982, lat: 34.95880360154695},
  {lng: 114.98089003301163, lat: 34.95885537276218},
  {lng: 114.9805801176107, lat: 34.958888654240276},
  {lng: 114.98039596498116, lat: 34.95894412334011},
  {lng: 114.98017588013121, lat: 34.95901068620988},
  {lng: 114.97995579528126, lat: 34.959088342822355},
  {lng: 114.97979859181702, lat: 34.959147509715336},
  {lng: 114.97968630362827, lat: 34.95921037449191},
  {lng: 114.97957850696707, lat: 34.959295426759226},
  {lng: 114.97949316794363, lat: 34.959350895581224},
  {lng: 114.97942130350283, lat: 34.9594211560347},
  {lng: 114.97929554073143, lat: 34.959583864219894},


  {lng: 114.97912037115698, lat: 34.95982053008914},
  {lng: 114.97890926936213, lat: 34.96018662124701},
  {lng: 114.97877003200809, lat: 34.96051203422408},
  {lng: 114.97857689632345, lat: 34.96090030936553},
  {lng: 114.9784646081347, lat: 34.96114066923704},
  {lng: 114.97839723522145, lat: 34.96145498491861},
  {lng: 114.97800647232461, lat: 34.962826866179114},
  {lng: 114.97792113330117, lat: 34.9631078955986},
  {lng: 114.97759325179003, lat: 34.96385113977022},
  {lng: 114.97735070930233, lat: 34.964268980521034},
  {lng: 114.97699138709834, lat: 34.96487540044062},
  {lng: 114.97674884461064, lat: 34.965271050019844},
  {lng: 114.97630867491075, lat: 34.96577762657305},
  {lng: 114.97614697991895, lat: 34.96599208868707},
  {lng: 114.9760346917302, lat: 34.96611410998185},
  {lng: 114.97545528467627, lat: 34.9666909354383},
  {lng: 114.97528460662937, lat: 34.966890604833786},
  {lng: 114.97504655566924, lat: 34.96708287860236},
  {lng: 114.97470969110299, lat: 34.96734910306865},


  {lng: 114.97395062294706, lat: 34.968033148041194},
  {lng: 114.97387875850626, lat: 34.96815516626839},
  {lng: 114.97381587712056, lat: 34.968258696741586},
  {lng: 114.97375299573487, lat: 34.96836222708275},
  {lng: 114.97367214823896, lat: 34.968510127341126},
  {lng: 114.97362723296347, lat: 34.96860995986318},
  {lng: 114.97358231768797, lat: 34.96868760729548},
  {lng: 114.97353740241248, lat: 34.96878374210875},
  {lng: 114.97350147019208, lat: 34.9689686164297},
  {lng: 114.97346553797168, lat: 34.96911651558332},
  {lng: 114.97325443617683, lat: 34.96986709963433},
  {lng: 114.97317358868092, lat: 34.97013701239951},
  {lng: 114.97307477507483, lat: 34.97038104234621},
  {lng: 114.97299841910649, lat: 34.97054742598019},
  {lng: 114.97294002924833, lat: 34.970883889620126},
  {lng: 114.97294452077588, lat: 34.97104287744831},
  {lng: 114.97297146994119, lat: 34.971164891151794},
  {lng: 114.97319604631868, lat: 34.971956127080986},
  {lng: 114.97326791075947, lat: 34.97244047797966},
  {lng: 114.97327240228702, lat: 34.97260685739243},
  {lng: 114.97328138534212, lat: 34.972765841846},
  {lng: 114.97329036839723, lat: 34.972873063743535},
  {lng: 114.97329036839723, lat: 34.972995074696016},
  {lng: 114.97328587686968, lat: 34.97311708546509},
  {lng: 114.97328587686968, lat: 34.973183636716364},
  {lng: 114.97343858880637, lat: 34.97356075944296},
  {lng: 114.97352841935736, lat: 34.973704952963594},
  {lng: 114.97361375838082, lat: 34.97392678864901},
  {lng: 114.97371257198691, lat: 34.97415971546617},
  {lng: 114.97377096184506, lat: 34.974377852672944},
  {lng: 114.97400002975012, lat: 34.975010077536574},
  {lng: 114.97407189419091, lat: 34.975376100206745},
  {lng: 114.97420214848985, lat: 34.97583455051557},
  {lng: 114.9742425722378, lat: 34.97595655701631},
  {lng: 114.97426502987555, lat: 34.976518523985064},
  {lng: 114.97428299598575, lat: 34.976718169208254},
  {lng: 114.97425604682046, lat: 34.97684756862764},
  {lng: 114.974170707797, lat: 34.97703981881263},
  {lng: 114.97412130099396, lat: 34.97710636684747},
  {lng: 114.97404943655316, lat: 34.97712115529225},
  {lng: 114.97396858905726, lat: 34.9771581263924},
  {lng: 114.9738922330889, lat: 34.97719509747573},
  {lng: 114.97374401267976, lat: 34.977232068542186},


  {lng: 114.972145028872, lat: 34.977757055868466},
  {lng: 114.97101765545698, lat: 34.97813046028021},
  {lng: 114.97029451952145, lat: 34.97836337503332},
  {lng: 114.96917612916153, lat: 34.97871459442872},
  {lng: 114.96857875599738, lat: 34.97898447777346},
  {lng: 114.96798138283326, lat: 34.97921739007546},
  {lng: 114.96757265382621, lat: 34.979402240633},


  {lng: 114.96741994188952, lat: 34.97945769571815},
  {lng: 114.96723578925997, lat: 34.97949096875105},
  {lng: 114.96707858579573, lat: 34.97953163577274},
  {lng: 114.96682256872539, lat: 34.97956490877532},
  {lng: 114.96657553471015, lat: 34.97959448476617},
  {lng: 114.96295087197738, lat: 34.98000854750615},
  {lng: 114.95898485315082, lat: 34.98041521421216},
  {lng: 114.95742629309102, lat: 34.980696182745845},
  {lng: 114.95719273365842, lat: 34.98072575832438},
  {lng: 114.95697714033602, lat: 34.98077012167196},
  {lng: 114.95677053006874, lat: 34.98080339416674},
  {lng: 114.95654146216368, lat: 34.980825575822344},
  {lng: 114.95633036036884, lat: 34.9808514544129},
  {lng: 114.9561731569046, lat: 34.98085515135372},
  {lng: 114.95599798733015, lat: 34.98085515135372},
  {lng: 114.9557150210945, lat: 34.9808514544129},
  {lng: 114.95546798707926, lat: 34.9808514544129},
  {lng: 114.95518951237116, lat: 34.98085884829439},
  {lng: 114.95498290210388, lat: 34.980847757471885},
  {lng: 114.95482120711208, lat: 34.98083666664788},
  {lng: 114.95461908837234, lat: 34.98083666664788},
  {lng: 114.95254400264429, lat: 34.980799697223546},
  {lng: 114.9521936634954, lat: 34.98084036358938},
  {lng: 114.951978070173, lat: 34.98087363605536},
  {lng: 114.95176696837815, lat: 34.98088842381362},
  {lng: 114.9515917988037, lat: 34.98091430238412},
  {lng: 114.95133129020581, lat: 34.980917999322095},
  {lng: 114.95114713757627, lat: 34.98090690850769},
  {lng: 114.95049586608154, lat: 34.9807479400012},
  {lng: 114.95019942526324, lat: 34.98063703155645},
  {lng: 114.94873518728198, lat: 34.98017121443312},
  {lng: 114.94855552617999, lat: 34.98014163865247},
  {lng: 114.94846120410143, lat: 34.98012315378409},
  {lng: 114.94777849191385, lat: 34.980167517461126},
  {lng: 114.9454967959185, lat: 34.98035236585437},
  {lng: 114.94535755856447, lat: 34.98036715370764},
  {lng: 114.94497128719517, lat: 34.980359759781344},
  {lng: 114.94449967680244, lat: 34.98037085067054},
  {lng: 114.94419425292904, lat: 34.98040782029023},
  {lng: 114.9440639986301, lat: 34.98045218381164},
  {lng: 114.9436148458751, lat: 34.980492850351624},
  {lng: 114.94345315088331, lat: 34.98044848685245},
  {lng: 114.94317467617522, lat: 34.980341274962655},
  {lng: 114.94228086219279, lat: 34.97992351693729},
  {lng: 114.94215060789384, lat: 34.9798717591561},
  {lng: 114.94174637041435, lat: 34.97979412242242},
  {lng: 114.94097831920332, lat: 34.979453998713645},
  {lng: 114.94083908184928, lat: 34.97937266457209},
  {lng: 114.94071331907787, lat: 34.979302421384226},
  {lng: 114.94057408172382, lat: 34.97920629902861},
  {lng: 114.93729975813996, lat: 34.976777323254154},
  {lng: 114.93534594365576, lat: 34.97548331865613},
  {lng: 114.9352606046323, lat: 34.97538719177704},
  {lng: 114.93521119782926, lat: 34.975268881615726},
  {lng: 114.93517077408131, lat: 34.97518014888156},
  {lng: 114.93510789269561, lat: 34.975065535623024},
  {lng: 114.93505399436502, lat: 34.97497310545792},
  {lng: 114.93498662145177, lat: 34.97486958354802},
  {lng: 114.93488331631812, lat: 34.97479563924585},
  {lng: 114.93481145187732, lat: 34.974714300435636},
  {lng: 114.93438924828763, lat: 34.9743630637283},
  {lng: 114.93158204356895, lat: 34.97224082223414},


  {lng: 114.9276250077975, lat: 34.96934206127382},
  {lng: 114.92502890487366, lat: 34.96743044925922},
  {lng: 114.9234119549557, lat: 34.96625092151854},
  {lng: 114.92147161505414, lat: 34.96480884237804},
  {lng: 114.91887102060277, lat: 34.96288603034825},
  {lng: 114.91843983395798, lat: 34.96256062688451},
  {lng: 114.91832754576923, lat: 34.96248667136998},
  {lng: 114.91821974910803, lat: 34.96241271578809},
  {lng: 114.91812991855703, lat: 34.9623609468407},
  {lng: 114.91789635912444, lat: 34.96221673317034},
  {lng: 114.91773915566019, lat: 34.96213168396262},
  {lng: 114.9175774606684, lat: 34.96205403026041},
  {lng: 114.9174427148419, lat: 34.96201335448197},
  {lng: 114.91731246054295, lat: 34.96195788747857},
  {lng: 114.91715974860625, lat: 34.96191721165198},
  {lng: 114.91703398583486, lat: 34.96189872263316},
  {lng: 114.91673754501656, lat: 34.96184325555146},
  {lng: 114.91663873141047, lat: 34.96182476651579},
  {lng: 114.91655339238702, lat: 34.961798881858805},
  {lng: 114.91635127364728, lat: 34.96174341470889},
  {lng: 114.91285237368591, lat: 34.960933590005546},
  {lng: 114.91015296562843, lat: 34.96034193214872},
  {lng: 114.90733677785464, lat: 34.95969110352734},
  {lng: 114.90721999813834, lat: 34.959654124471804},
  {lng: 114.9071077099496, lat: 34.959594957947914},
  {lng: 114.90703135398125, lat: 34.95952839555701},
  {lng: 114.90698643870576, lat: 34.95946183311156},
  {lng: 114.90688313357211, lat: 34.959354593501345},
  {lng: 114.90675287927316, lat: 34.95926214544783},
  {lng: 114.90665855719462, lat: 34.95916599936057},
  {lng: 114.90654177747831, lat: 34.95899959240205},
  {lng: 114.90558957363774, lat: 34.95805291521724},

  {lng: 114.9034336404138, lat: 34.95684551599885},
  {lng: 114.90027160501867, lat: 34.954774590398955},
  {lng: 114.89617533189316, lat: 34.953354496612675},
  {lng: 114.8945224497548, lat: 34.952940297914935},
  {lng: 114.89401939866922, lat: 34.95181603366038},
  {lng: 114.89394753422842, lat: 34.950750926855555},
  {lng: 114.89243838097165, lat: 34.94998167436702},
  {lng: 114.89121668547808, lat: 34.94956745847146},
  {lng: 114.88812651452376, lat: 34.94974497982817},
  {lng: 114.88690481903019, lat: 34.94938993672685},
  {lng: 114.88561125909582, lat: 34.948561496789615},
  {lng: 114.87813735725281, lat: 34.94595777341925},
  {lng: 114.87619701735126, lat: 34.94483341253237},
  {lng: 114.87382549080492, lat: 34.94376821418235},
  {lng: 114.87001667544261, lat: 34.941105157215986},
  {lng: 114.86714209781069, lat: 34.93873792220344},
  {lng: 114.86534548679073, lat: 34.93666653500446},
  {lng: 114.8626865024812, lat: 34.933174648511695},
  {lng: 114.86160853586922, lat: 34.931517429553374},
  {lng: 114.86139294254683, lat: 34.93074799498161},
  {lng: 114.86137946796417, lat: 34.93069065701762},
]


let Helihe = [
  {lng: 115.26974915280014, lat: 34.83225027026822},
  {lng: 115.26720694820692, lat: 34.830894705296075},
  {lng: 115.26700932099472, lat: 34.83080581500292},
  {lng: 115.26686559211312, lat: 34.8306872944614},
  {lng: 115.26678474461723, lat: 34.83055395864642},
  {lng: 115.26658711740502, lat: 34.83039099235445},
  {lng: 115.26648830379894, lat: 34.830235433317604},
  {lng: 115.26588643910725, lat: 34.82850944886714},
  {lng: 115.26586847299704, lat: 34.828272400941195},
  {lng: 115.26529355747066, lat: 34.826842691037534},
  {lng: 115.26461084528307, lat: 34.82550926026365},
  {lng: 115.26450304862188, lat: 34.825427772121095},
  {lng: 115.2637394889384, lat: 34.82499069796787},
  {lng: 115.26340711589971, lat: 34.82470178325958},
  {lng: 115.2631825395222, lat: 34.824509172885854},
  {lng: 115.26296694619981, lat: 34.8243461945223},
  {lng: 115.26233813234283, lat: 34.82379058401879},
  {lng: 115.26211355596534, lat: 34.82363501240002},
  {lng: 115.26194287791844, lat: 34.82344239950879},
  {lng: 115.26020914828419, lat: 34.821694046323636},
  {lng: 115.25790050312354, lat: 34.819293703833175},
  {lng: 115.25395694193473, lat: 34.815218886768186},
  {lng: 115.2517021951047, lat: 34.81245530418628},
  {lng: 115.25009422824184, lat: 34.810358475370144},
  {lng: 115.24996846547043, lat: 34.81023992513636},
  {lng: 115.24985168575414, lat: 34.81014360294467},
  {lng: 115.24865693942587, lat: 34.80951380119898},
  {lng: 115.24811795611988, lat: 34.80933597394437},
  {lng: 115.24778558308118, lat: 34.80930633603096},
  {lng: 115.24739032865679, lat: 34.809284107588844},
  {lng: 115.24670761646921, lat: 34.809343383421044},
  {lng: 115.24624049760402, lat: 34.809395249738934},

  {lng: 115.24230142794278, lat: 34.80985834040282},
  {lng: 115.23817820565198, lat: 34.810273267407005},
  {lng: 115.234715237911, lat: 34.810914177296816},
  {lng: 115.23379896629083, lat: 34.81119573149624},
  {lng: 115.23309828799304, lat: 34.81138837328386},
  {lng: 115.23237964358506, lat: 34.811581014617005},
  {lng: 115.23155320251588, lat: 34.81181811101851},
  {lng: 115.23031354091212, lat: 34.81230712017239},
  {lng: 115.22591184391322, lat: 34.814707668087216},
  {lng: 115.22555252170923, lat: 34.81485584774448},
  {lng: 115.22519319950524, lat: 34.81497439127666},
  {lng: 115.22472608064005, lat: 34.81501884505684},
  {lng: 115.22053997696356, lat: 34.81584864451096},
  {lng: 115.21658743271965, lat: 34.816989604989466},
  {lng: 115.21308404123074, lat: 34.818634338137514},
  {lng: 115.21267082069615, lat: 34.818767693426956},
  {lng: 115.21207793905957, lat: 34.81888623127913},
  {lng: 115.21132336243119, lat: 34.81888623127913},
  {lng: 115.2105148874722, lat: 34.81894550014067},
  {lng: 115.21002980249682, lat: 34.819034403352305},
  {lng: 115.20817929314626, lat: 34.81910848928802},

  {lng: 115.20759988609232, lat: 34.8191233064671},
  {lng: 115.20473878304304, lat: 34.819330746691776},
  {lng: 115.20344522310867, lat: 34.81940112807683},
  {lng: 115.20153632389997, lat: 34.81945298800591},
  {lng: 115.19978013662796, lat: 34.8194566922853},
  {lng: 115.19776793228561, lat: 34.81964931458189},
  {lng: 115.19467776133129, lat: 34.819886387553716},
  {lng: 115.19317309960208, lat: 34.820012332289956},
  {lng: 115.19240504839105, lat: 34.81991972588517},
  {lng: 115.19200081091155, lat: 34.819916021626796},
  {lng: 115.19046021696195, lat: 34.81991231736824},
  {lng: 115.18899148745314, lat: 34.819860457730954},
  {lng: 115.18569021470397, lat: 34.81976785115386},
  {lng: 115.18295487442607, lat: 34.81974192129345},
  {lng: 115.18090224633578, lat: 34.819764146888595},
  {lng: 115.1783016518844, lat: 34.81982341511273},
  {lng: 115.17548546411061, lat: 34.819908613109526},

  {lng: 115.17258393731339, lat: 34.8199567684597},
  {lng: 115.1718473267952, lat: 34.82003085355831},
  {lng: 115.16356944152075, lat: 34.82123102278847},
  {lng: 115.16342571263915, lat: 34.82125324797816},
  {lng: 115.16333139056061, lat: 34.82129028994754},
  {lng: 115.16323257695451, lat: 34.821345852870095},
  {lng: 115.16311130571067, lat: 34.82141252832726},
  {lng: 115.16291367849847, lat: 34.821582920914736},
  {lng: 115.15826494748434, lat: 34.82483142460134},
  {lng: 115.15530053930141, lat: 34.82678342791021},
  {lng: 115.15286613136936, lat: 34.82847241017409},
  {lng: 115.15131655436466, lat: 34.82956874837214},
  {lng: 115.15025206233533, lat: 34.83027617499879},
  {lng: 115.15004994359559, lat: 34.8304132150498},
  {lng: 115.1499331638793, lat: 34.83045766042233},
  {lng: 115.14980290958034, lat: 34.830494698214274},
  {lng: 115.14965019764365, lat: 34.83051321710394},
  {lng: 115.14951545181715, lat: 34.83055025487068},
  {lng: 115.1494031636284, lat: 34.830568773747736},
  {lng: 115.14912019739276, lat: 34.83065396052808},
  {lng: 115.14454782234698, lat: 34.831302117900705},
  {lng: 115.14447146637862, lat: 34.83132434034791},
  {lng: 115.14266587230357, lat: 34.83163545397326},
  {lng: 115.13719070022026, lat: 34.832476195574095},
  {lng: 115.13255544378876, lat: 34.83323544816574},
  {lng: 115.12829747567147, lat: 34.83392432489158},
  {lng: 115.12590798301493, lat: 34.834331722368766},
  {lng: 115.1251578979141, lat: 34.834453941215216},
  {lng: 115.1250680673631, lat: 34.834450237616494},
  {lng: 115.12498272833966, lat: 34.83446505201036},
  {lng: 115.12480306723766, lat: 34.83453542034448},
  {lng: 115.12357688021653, lat: 34.83469467477126},
  {lng: 115.1209538281274, lat: 34.83481318949177},
  {lng: 115.12059001439586, lat: 34.83480578232678},
  {lng: 115.12043281093162, lat: 34.83478726441136},
  {lng: 115.12033848885306, lat: 34.83479467157803},
  {lng: 115.12023967524696, lat: 34.834809485909354},
  {lng: 115.12015882775107, lat: 34.83484281814499},
  {lng: 115.1187574711555, lat: 34.83489466826225},
  {lng: 115.11852391172292, lat: 34.83486133604779},

  {lng: 115.11818255562912, lat: 34.83487615036701},
  {lng: 115.11805679285773, lat: 34.834868743207736},
  {lng: 115.11790408092102, lat: 34.83486133604779},
  {lng: 115.11784119953533, lat: 34.83487615036701},
  {lng: 115.11776933509454, lat: 34.834883557525615},
  {lng: 115.11761662315783, lat: 34.83489096468354},
  {lng: 115.11752679260684, lat: 34.83489096468354},
  {lng: 115.11746391122114, lat: 34.834898371840794},
  {lng: 115.11724831789874, lat: 34.83490577899737},
  {lng: 115.11686204652945, lat: 34.83492059330852},
  {lng: 115.11666441931726, lat: 34.83492800046307},
  {lng: 115.11626018183777, lat: 34.83497244337635},
  {lng: 115.11607153768067, lat: 34.83498725767538},
  {lng: 115.11593679185418, lat: 34.83500947911891},
  {lng: 115.11582899519298, lat: 34.8350168862654},
  {lng: 115.11570323242158, lat: 34.835024293411216},
  {lng: 115.11561340187059, lat: 34.83503170055637},
  {lng: 115.11552357131959, lat: 34.83506873627203},
  {lng: 115.11534391021759, lat: 34.83510577197088},
  {lng: 115.11521814744619, lat: 34.835113179108625},


  {lng: 115.11141831513899, lat: 34.83553538484881},
  {lng: 115.11134645069819, lat: 34.835505756447105},
  {lng: 115.11119373876149, lat: 34.835498349345},
  {lng: 115.1110230607146, lat: 34.835520570649315},
  {lng: 115.1109332301636, lat: 34.83554279194757},
  {lng: 115.1109242471085, lat: 34.835587234525924},
  {lng: 115.10602848207911, lat: 34.83614276471264},
  {lng: 115.10596560069342, lat: 34.83609832243691},
  {lng: 115.10590271930772, lat: 34.83609091538861},
  {lng: 115.10577695653632, lat: 34.836105729484544},
  {lng: 115.10565119376493, lat: 34.83611313653151},
  {lng: 115.10555238015883, lat: 34.836135357668354},
  {lng: 115.10555238015883, lat: 34.83615757879916},
  {lng: 115.1022196667168, lat: 34.836527930088124},


  {lng: 115.10216576838621, lat: 34.83649830204688},
  {lng: 115.10213881922091, lat: 34.83645385996483},
  {lng: 115.10201305644952, lat: 34.8364908950349},
  {lng: 115.10180644618222, lat: 34.8365057090582},
  {lng: 115.10167170035572, lat: 34.836527930088124},
  {lng: 115.10162678508023, lat: 34.836594593141584},
  {lng: 115.10136627648234, lat: 34.8366168141473},
  {lng: 115.10123153065584, lat: 34.83662422114786},
  {lng: 115.10106983566403, lat: 34.836639035146966},
  {lng: 115.10088119150694, lat: 34.836639035146966},
  {lng: 115.10067458123964, lat: 34.83664644214551},
  {lng: 115.10052186930295, lat: 34.83663162814775},
  {lng: 115.10039610653156, lat: 34.836594593141584},
  {lng: 115.10020746237446, lat: 34.83657977913442},
  {lng: 115.09722508808133, lat: 34.83597980958328},
  {lng: 115.09722508808133, lat: 34.83594277428121},
  {lng: 115.09722508808133, lat: 34.83592796015567},
  {lng: 115.09716220669563, lat: 34.835905738962325},
  {lng: 115.09709932530994, lat: 34.83589092483006},
  {lng: 115.09702746086914, lat: 34.83586870362664},
  {lng: 115.09692864726304, lat: 34.83586129655748},
  {lng: 115.09679390143654, lat: 34.83585388948765},
  {lng: 115.09665915561004, lat: 34.83586129655748},
  {lng: 115.09300305218444, lat: 34.83505392198779},
  {lng: 115.09304796745994, lat: 34.835024293411216},
  {lng: 115.09294017079874, lat: 34.8349798505262},
  {lng: 115.09274254358654, lat: 34.834950221922746},
  {lng: 115.09256288248454, lat: 34.83492800046307},
  {lng: 115.09250000109886, lat: 34.834950221922746},
  {lng: 115.08842169408355, lat: 34.83409839497164},

  {lng: 115.08843067713865, lat: 34.83405395158225},
  {lng: 115.08834982964275, lat: 34.83401691540594},
  {lng: 115.08820610076116, lat: 34.83399469369208},
  {lng: 115.08805338882446, lat: 34.8339502502462},
  {lng: 115.08797254132855, lat: 34.834031729878475},
  {lng: 115.08576270977402, lat: 34.83349099988782},
  {lng: 115.08556508256181, lat: 34.833461370743684},
  {lng: 115.08546626895573, lat: 34.83344655616756},
  {lng: 115.08533152312923, lat: 34.8334391488785},
  {lng: 115.08511592980683, lat: 34.8334391488785},
  {lng: 115.08490931953953, lat: 34.833453963455945},
  {lng: 115.08311270851958, lat: 34.833587294531995},
  {lng: 115.08307677629918, lat: 34.83352803630287},
  {lng: 115.08295101352779, lat: 34.83352803630287},
  {lng: 115.08274440326049, lat: 34.83353544358387},
  {lng: 115.082699487985, lat: 34.83353544358387},
  {lng: 115.08269050492989, lat: 34.83359470180761},
  {lng: 115.08021118172235, lat: 34.83374284717865},
  {lng: 115.07918711344098, lat: 34.83384654877832},
  {lng: 115.07915118122058, lat: 34.83379469799496},
  {lng: 115.07879185901659, lat: 34.833816919763294},
  {lng: 115.07877389290638, lat: 34.833883585031984},
  {lng: 115.07459677228499, lat: 34.83409098774176},

  {lng: 115.07459677228499, lat: 34.83402432264254},
  {lng: 115.0744440603483, lat: 34.834031729878475},
  {lng: 115.0743721959075, lat: 34.83402432264254},
  {lng: 115.0741835517504, lat: 34.834031729878475},
  {lng: 115.0740937211994, lat: 34.8341132094294},
  {lng: 115.07290795792623, lat: 34.834120616657266},
  {lng: 115.07286304265074, lat: 34.834061358815504},
  {lng: 115.07253066961204, lat: 34.83405395158225},
  {lng: 115.07212643213255, lat: 34.8341132094294},
  {lng: 115.0700513464045, lat: 34.834216910558325},
  {lng: 115.06763490458266, lat: 34.83435024038756},
  {lng: 115.0659281241137, lat: 34.834476162803995},

  {lng: 115.06493100499763, lat: 34.83449838438671},
  {lng: 115.06449083529773, lat: 34.83446134841214},
  {lng: 115.06400575032235, lat: 34.834513198771845},
  {lng: 115.06320625841848, lat: 34.83460949220955},
  {lng: 115.06285591926958, lat: 34.83469097118347},
  {lng: 115.0624516817901, lat: 34.83472059988108},
  {lng: 115.0621103256963, lat: 34.83476504290732},
  {lng: 115.06155337628012, lat: 34.83477985724403},
  {lng: 115.06087964714763, lat: 34.83485392888718},
  {lng: 115.06064608771503, lat: 34.83485392888718},
  {lng: 115.06046642661303, lat: 34.83487615036701},
  {lng: 115.06035862995184, lat: 34.83489096468354},
  {lng: 115.06033168078653, lat: 34.83491318615329},

  {lng: 115.05846769685334, lat: 34.83514280765291},
  {lng: 115.05712023858837, lat: 34.8353798356198},
  {lng: 115.05690015373843, lat: 34.83540205695639},
  {lng: 115.05662617055788, lat: 34.83542427828692},
  {lng: 115.05646896709364, lat: 34.8354650173772},
  {lng: 115.05632523821204, lat: 34.835505756447105},
  {lng: 115.05616354322024, lat: 34.835572420335836},
  {lng: 115.05436244067273, lat: 34.83627609139455},
  {lng: 115.05345066058011, lat: 34.836598296642954},
  {lng: 115.05253888048748, lat: 34.83685754132125},
  {lng: 115.05151930373366, lat: 34.83719085469711},
  {lng: 115.04986193006775, lat: 34.83774267051461},
  {lng: 115.04921515010057, lat: 34.83796487717216},
  {lng: 115.0477419290642, lat: 34.83849446726567},
  {lng: 115.04691997952257, lat: 34.838742595707096},
  {lng: 115.04600819942993, lat: 34.83904997765506},
  {lng: 115.04509192780976, lat: 34.839338841562494},
  {lng: 115.04476853782617, lat: 34.83944994279298},
  {lng: 115.04446760548034, lat: 34.839583264069816},
  {lng: 115.04424302910283, lat: 34.83970547504883},
  {lng: 115.04299887597152, lat: 34.84059057334082},
  {lng: 115.04106751912506, lat: 34.84193486743823},
  {lng: 115.03940116240405, lat: 34.843156933755374},
  {lng: 115.03895200964907, lat: 34.84350873733111},
  {lng: 115.03724972070765, lat: 34.844682639335254},
  {lng: 115.03607294048959, lat: 34.845497324800114},
  {lng: 115.03573158439579, lat: 34.845678776363656},
  {lng: 115.03549353343566, lat: 34.845812087459194},
  {lng: 115.03524649942041, lat: 34.845897258322815},
  {lng: 115.03485573652357, lat: 34.84596761679524},
  {lng: 115.03448743126448, lat: 34.84603797520696},
  {lng: 115.03406971920234, lat: 34.84611573969666},
  {lng: 115.03372836310854, lat: 34.84621201943831},
  {lng: 115.03304565092095, lat: 34.84634162660335},
  {lng: 115.03078192103581, lat: 34.84688967748125},
  {lng: 115.03078641256336, lat: 34.846911895682396},
  {lng: 115.03056632771342, lat: 34.84704890778905},
  {lng: 115.03049446327262, lat: 34.847071125946826},
  {lng: 115.03026988689513, lat: 34.847148889451326},
  {lng: 115.03015759870638, lat: 34.84714148340648},
  {lng: 115.03008573426558, lat: 34.8471229682914},
  {lng: 115.02991056469114, lat: 34.847115562244184},
  {lng: 115.02978480191975, lat: 34.8471229682914},
  {lng: 115.02965005609325, lat: 34.8471377803838},
  {lng: 115.0293221745821, lat: 34.847148889451326},
  {lng: 115.02895836085057, lat: 34.84721924684389},


  {lng: 115.02815437741913, lat: 34.84737107048418},
  {lng: 115.02645658000527, lat: 34.84771915288523},
  {lng: 115.02618708835227, lat: 34.84779321277888},
  {lng: 115.02530225742495, lat: 34.84800428310673},
  {lng: 115.02388293471918, lat: 34.84841901619463},
  {lng: 115.02170454385748, lat: 34.84911146763149},
  {lng: 115.02027623809663, lat: 34.84957803587203},
  {lng: 115.01868623734396, lat: 34.85001868120346},
  {lng: 115.01765767753504, lat: 34.850303803384314},
  {lng: 115.01660666008836, lat: 34.85058151883573},
  {lng: 115.01645394815166, lat: 34.85061114176138},
  {lng: 115.01619793108132, lat: 34.85066298185538},
  {lng: 115.01367369259829, lat: 34.85109991848093},
  {lng: 115.01268106500976, lat: 34.851262843403},
  {lng: 115.01205674268033, lat: 34.851359117067545},
  {lng: 115.01163453909064, lat: 34.851433173655245},
  {lng: 115.01113148800505, lat: 34.85155166405564},
  {lng: 115.00991877556658, lat: 34.85187380896125},
  {lng: 115.00924055490654, lat: 34.852081165928325},
  {lng: 115.0086611478526, lat: 34.85225890005181},
  {lng: 115.00750233374474, lat: 34.85259215048905},
  {lng: 115.00685106225, lat: 34.85275136966154},
  {lng: 115.00594826521248, lat: 34.85289207471796},
  {lng: 115.00373394213038, lat: 34.85327345825503},
  {lng: 115.00160495807174, lat: 34.8536030018163},
  {lng: 115.00051800840465, lat: 34.853802948945756},
  {lng: 114.99996105898848, lat: 34.853921435902194},
  {lng: 114.99770182063088, lat: 34.85444721969387},
  {lng: 114.99658343027096, lat: 34.8547434344212},
  {lng: 114.99623758264961, lat: 34.85482489328255},
  {lng: 114.99572554850893, lat: 34.85496189209306},
  {lng: 114.99517309062028, lat: 34.85510999865625},
  {lng: 114.99445893773985, lat: 34.855354373897285},
  {lng: 114.99380766624512, lat: 34.855595045767174},
  {lng: 114.99312046252999, lat: 34.855846824809056},
  {lng: 114.99238385201181, lat: 34.85608749522512},
  {lng: 114.99143164817123, lat: 34.856413324810134},
  {lng: 114.99107232596724, lat: 34.85653180797271},
  {lng: 114.99096902083359, lat: 34.85656142873644},
  {lng: 114.99091063097545, lat: 34.85656142873644},
  {lng: 114.99088368181015, lat: 34.85655402354652},
  {lng: 114.99085673264484, lat: 34.85653551056877},
  {lng: 114.9907893597316, lat: 34.8564947820029},
  {lng: 114.99033571544906, lat: 34.85611341353524},


]

let Zhangxinhe = [
  {lng: 115.18581148594781, lat: 34.816685844341656},
  {lng: 115.18453589212365, lat: 34.81677104561302},
  {lng: 115.18266292513533, lat: 34.816993309380656},
  {lng: 115.18162987379887, lat: 34.81711184980929},
  {lng: 115.1798961441646, lat: 34.817315590768885},
  {lng: 115.17724614291016, lat: 34.81755267051814},
  {lng: 115.17712487166632, lat: 34.817523035587136},
  {lng: 115.17698563431227, lat: 34.817523035587136},
  {lng: 115.17689580376128, lat: 34.81753044432089},
  {lng: 115.17682843084803, lat: 34.81757119234455},
  {lng: 115.1755932607718, lat: 34.817611940347874},
  {lng: 115.17496893844238, lat: 34.817615644710806},
  {lng: 115.17486114178118, lat: 34.81760453162151},
  {lng: 115.17468597220673, lat: 34.817593418530706},
  {lng: 115.17455122638023, lat: 34.81756748797961},
  {lng: 115.17444792124658, lat: 34.8175452617864},
  {lng: 115.17425927708949, lat: 34.81753044432089},
  {lng: 115.1741155482079, lat: 34.81751192248523},
  {lng: 115.1736933446182, lat: 34.81748969627689},
  {lng: 115.17352266657132, lat: 34.81748969627689},
  {lng: 115.17333851394177, lat: 34.81748969627689},
  {lng: 115.17292080187963, lat: 34.81751562685269},
  {lng: 115.17276359841539, lat: 34.81753044432089},
  {lng: 115.17265580175419, lat: 34.817556374883765},
  {lng: 115.17258393731339, lat: 34.81756748797961},
  {lng: 115.17254351356543, lat: 34.81757119234455},
  {lng: 115.17244919148689, lat: 34.81756007924921},
  {lng: 115.17233690329815, lat: 34.817600827258076},
  {lng: 115.17225156427469, lat: 34.81762305343616},
  {lng: 115.17216622525125, lat: 34.81764898396961},

  {lng: 115.1719551234564, lat: 34.81767491449482},
  {lng: 115.17155986903201, lat: 34.817719366804596},
  {lng: 115.17134876723716, lat: 34.817737888593186},
  {lng: 115.17122749599332, lat: 34.817756410377584},
  {lng: 115.17105232641887, lat: 34.81778974957891},
  {lng: 115.17073791949038, lat: 34.817819384413085},
  {lng: 115.17056274991593, lat: 34.817841610531666},
  {lng: 115.17033368201089, lat: 34.81787865404919},
  {lng: 115.16874817278578, lat: 34.81800089753775},
  {lng: 115.16694707023828, lat: 34.818119436503345},
  {lng: 115.16462045896743, lat: 34.81823056662728},
  {lng: 115.16226240700374, lat: 34.818360218247335},
  {lng: 115.16197944076809, lat: 34.81838244421866},
  {lng: 115.1616829999498, lat: 34.81840837451091},
  {lng: 115.1614853727376, lat: 34.818423191817054},
  {lng: 115.16131918621826, lat: 34.81843800912051},
  {lng: 115.16101376234487, lat: 34.81848246101478},
  {lng: 115.16074427069188, lat: 34.81853061720624},
  {lng: 115.16017384669304, lat: 34.81861211223477},
  {lng: 115.15994028726044, lat: 34.818638042454054},
  {lng: 115.15876350704238, lat: 34.81886030113868},
  {lng: 115.1581167270752, lat: 34.81896031734933},
  {lng: 115.1578472354222, lat: 34.81905292484253},
  {lng: 115.15686359088878, lat: 34.8193233381214},
  {lng: 115.15471214919238, lat: 34.82000121952693},
  {lng: 115.15407435228029, lat: 34.820230882988746},
  {lng: 115.1535533350845, lat: 34.82044943310241},
  {lng: 115.15313562302237, lat: 34.8205864896548},
  {lng: 115.15275384318062, lat: 34.82071613753311},
  {lng: 115.15259214818883, lat: 34.820812447252266},
  {lng: 115.15202621571754, lat: 34.82098284109241},
  {lng: 115.15160401212785, lat: 34.821123600952944},
  {lng: 115.15013079109148, lat: 34.821590329280035},
  {lng: 115.1494750280692, lat: 34.82180517158126},
  {lng: 115.14864409547248, lat: 34.82209409654303},


  {lng: 115.14837011229193, lat: 34.82214595476127},
  {lng: 115.14756163733296, lat: 34.82247932823453},
  {lng: 115.14710350152286, lat: 34.8226830557979},
  {lng: 115.14676214542907, lat: 34.822831220979154},
  {lng: 115.14653307752403, lat: 34.822912711714196},
  {lng: 115.14583239922624, lat: 34.82316829576451},
  {lng: 115.14565722965179, lat: 34.82324608205577},
  {lng: 115.14550002618755, lat: 34.82328682722636},
  {lng: 115.14530239897536, lat: 34.82333127648017},
  {lng: 115.14512722940091, lat: 34.82337942981115},
  {lng: 115.1450104496846, lat: 34.82341276671595},
  {lng: 115.14487121233057, lat: 34.82346462409635},
  {lng: 115.14474544955917, lat: 34.823494256870376},
  {lng: 115.14468256817347, lat: 34.82351277734867},
  {lng: 115.14410765264708, lat: 34.823668349200425},
  {lng: 115.1436135846166, lat: 34.823768359519974},
  {lng: 115.14298027923206, lat: 34.82383503299826},
  {lng: 115.14276019438212, lat: 34.82387207379599},
  {lng: 115.14255807564237, lat: 34.82392763496106},
  {lng: 115.14231553315467, lat: 34.82398319608833},
  {lng: 115.14218977038328, lat: 34.824012828674064},
  {lng: 115.1413004479284, lat: 34.824205440219124},
  {lng: 115.14103544780296, lat: 34.82424618491079},
  {lng: 115.14035273561538, lat: 34.824412867528544},
  {lng: 115.14023595589907, lat: 34.82443509185185},
  {lng: 115.14000239646649, lat: 34.82450546883576},
  {lng: 115.13980476925428, lat: 34.82459436599184},
  {lng: 115.13937807413706, lat: 34.82470178325958},


  {lng: 115.13828214141488, lat: 34.825005514079194},
  {lng: 115.13754553089669, lat: 34.82516849112618},
  {lng: 115.13713680188965, lat: 34.82527590763829},
  {lng: 115.13690324245707, lat: 34.82534998790911},
  {lng: 115.13672807288262, lat: 34.82542406811268},
  {lng: 115.13646756428471, lat: 34.82551666827258},
  {lng: 115.1356456147431, lat: 34.825987075462336},
  {lng: 115.1355827333574, lat: 34.826027819264546},
  {lng: 115.1354749366962, lat: 34.826072267025616},
  {lng: 115.1353851061452, lat: 34.82610930680799},
  {lng: 115.13528180101154, lat: 34.82612041873943},
  {lng: 115.13435205480873, lat: 34.82645377597902},
  {lng: 115.13406459704554, lat: 34.82654267101293},
  {lng: 115.13384900372314, lat: 34.82663897385707},
  {lng: 115.13349866457425, lat: 34.82674638843378},
  {lng: 115.1332606136141, lat: 34.826827875259745},
  {lng: 115.13309442709475, lat: 34.82688343441261},
  {lng: 115.13294171515805, lat: 34.82692417776733},
  {lng: 115.13286535918971, lat: 34.82695380928526},
  {lng: 115.13285188460706, lat: 34.827005664415765},
  {lng: 115.132802477804, lat: 34.827031591968655},
  {lng: 115.13273959641832, lat: 34.82704270377451},
  {lng: 115.13268120656016, lat: 34.82705751951331},
  {lng: 115.13260934211937, lat: 34.82706492738169},
  {lng: 115.13257340989897, lat: 34.82706492738169},
  {lng: 115.13255095226121, lat: 34.82704270377451},
  {lng: 115.131225951634, lat: 34.82735012980334},
  {lng: 115.12981112045578, lat: 34.827664962487994},


  {lng: 115.12695450893405, lat: 34.828302031969606},
  {lng: 115.12689611907591, lat: 34.82832055135689},
  {lng: 115.12674789866676, lat: 34.8283501823678},
  {lng: 115.12665806811576, lat: 34.82836870174416},
  {lng: 115.12649637312397, lat: 34.828376109493526},
  {lng: 115.12604272884143, lat: 34.82850574499858},
  {lng: 115.12587654232208, lat: 34.82855389527659},
  {lng: 115.12566094899968, lat: 34.828624268708715},
  {lng: 115.12541391498445, lat: 34.82869093822001},
  {lng: 115.12519383013449, lat: 34.82876871924768},
  {lng: 115.12499171139476, lat: 34.82883909249453},
  {lng: 115.12465933835607, lat: 34.82892057723089},
  {lng: 115.12459645697037, lat: 34.828931688779534},
  {lng: 115.12451560947446, lat: 34.82893539262876},
  {lng: 115.12441679586837, lat: 34.82892798493016},
  {lng: 115.12403052449908, lat: 34.82892057723089},
  {lng: 115.12342416827984, lat: 34.82892798493016},
  {lng: 115.1232310325952, lat: 34.8289242810806},
  {lng: 115.1230783206585, lat: 34.828931688779534},
  {lng: 115.12239111694336, lat: 34.828994654193316},
  {lng: 115.12219798125872, lat: 34.82900206188587},
  {lng: 115.12058103134076, lat: 34.82916503095172},
  {lng: 115.12051365842751, lat: 34.829198365493276},
  {lng: 115.12041933634896, lat: 34.82922058851341},
  {lng: 115.12017679386128, lat: 34.82923170002122},
  {lng: 115.12000162428683, lat: 34.82922799618544},

  {lng: 115.11861374227391, lat: 34.82937614948493},
  {lng: 115.11837569131376, lat: 34.82938726097155},
  {lng: 115.11825891159747, lat: 34.82939096480009},
  {lng: 115.11808374202302, lat: 34.829409483940246},
  {lng: 115.11663297862441, lat: 34.82939837245665},
  {lng: 115.11639043613671, lat: 34.82946133751033},
  {lng: 115.11611196142862, lat: 34.829487264283024},
  {lng: 115.11592780879907, lat: 34.82950578340132},
  {lng: 115.11583797824808, lat: 34.82947244898535},
  {lng: 115.11568526631139, lat: 34.8294650413355},
  {lng: 115.11558645270529, lat: 34.82949837575452},
  {lng: 115.11548314757164, lat: 34.82954652544681},
  {lng: 115.1152630627217, lat: 34.82957985983253},
  {lng: 115.1150295032891, lat: 34.82960949038617},
  {lng: 115.114751028581, lat: 34.829665047645264},
  {lng: 115.11441416401476, lat: 34.82973171630624},
  {lng: 115.11411772319647, lat: 34.82981320015125},
  {lng: 115.11380331626798, lat: 34.82988727630338},
  {lng: 115.11358323141803, lat: 34.829931721962375},
  {lng: 115.11342602795379, lat: 34.829950240979805},
  {lng: 115.11330026518239, lat: 34.82997987139896},
  {lng: 115.1130128074192, lat: 34.83002431700753},
  {lng: 115.11278373951416, lat: 34.83005394739979},
  {lng: 115.11254119702646, lat: 34.830068762591885},
  {lng: 115.11226721384591, lat: 34.83009098537499},
  {lng: 115.11202017983067, lat: 34.83012802333335},
  {lng: 115.11132399306044, lat: 34.83030580529958},
  {lng: 115.11126560320228, lat: 34.830294693938036},
  {lng: 115.11122517945434, lat: 34.83029099015052},
  {lng: 115.11111289126559, lat: 34.83030580529958},
  {lng: 115.1110679759901, lat: 34.83033173180396},
  {lng: 115.11100060307685, lat: 34.83037988100452},
  {lng: 115.11055594184941, lat: 34.83048729065723},
  {lng: 115.11030441630662, lat: 34.83053173598942},
  {lng: 115.11016967048012, lat: 34.830576181297396},
  {lng: 115.10998102632303, lat: 34.830616922808424},
  {lng: 115.10974297536288, lat: 34.83065766429912},
  {lng: 115.10954983967824, lat: 34.83069840576949},
  {lng: 115.10941060232419, lat: 34.830735443452156},
  {lng: 115.10927136497014, lat: 34.83075396228719},
  {lng: 115.10915458525385, lat: 34.83075766605369},
  {lng: 115.10894797498655, lat: 34.83075766605369},
  {lng: 115.1087907715223, lat: 34.83076507358618},
  {lng: 115.10862009347541, lat: 34.83076877735219},
  {lng: 115.10845390695606, lat: 34.83076877735219},
  {lng: 115.10812153391737, lat: 34.83079099994464},
  {lng: 115.10658093996776, lat: 34.83071692461292},
  {lng: 115.10632043136987, lat: 34.83075025852051},
  {lng: 115.10530534614358, lat: 34.8307391472195},
  {lng: 115.10483822727839, lat: 34.83072803591696},
  {lng: 115.10445195590911, lat: 34.83072062838111},
  {lng: 115.10410161676022, lat: 34.83072062838111},
  {lng: 115.10366593858788, lat: 34.83072062838111},
  {lng: 115.10289339584929, lat: 34.830679886921835},
  {lng: 115.1026598364167, lat: 34.83067618315181},
  {lng: 115.10214780227601, lat: 34.83065025675687},
  {lng: 115.10208042936277, lat: 34.830616922808424},
  {lng: 115.10186932756793, lat: 34.830639145442234},
  {lng: 115.10132585273438, lat: 34.83071692461292},
  {lng: 115.10108780177424, lat: 34.83076507358618},
  {lng: 115.10098449664059, lat: 34.83079840747411},
  {lng: 115.10090814067225, lat: 34.83082433382199},
  {lng: 115.10082729317635, lat: 34.830850260161604},
  {lng: 115.10077339484575, lat: 34.83087989025393},
  {lng: 115.10068356429476, lat: 34.830909520335524},
  {lng: 115.10056678457845, lat: 34.83094655792237},
  {lng: 115.1004275472244, lat: 34.83096507670949},
  {lng: 115.10031525903565, lat: 34.830961372952395},
  {lng: 115.1002838183428, lat: 34.83095396543772},
  {lng: 115.10023890306731, lat: 34.830942854164434},
  {lng: 115.10023441153976, lat: 34.83093544664808},
  {lng: 115.100211953902, lat: 34.83091692785423},
  {lng: 115.10020746237446, lat: 34.83089840905618},
  {lng: 115.10019398779181, lat: 34.83085766768569},
  {lng: 115.10018500473672, lat: 34.83078359241449},
  {lng: 115.10011314029592, lat: 34.82958356365232},
  {lng: 115.10007720807552, lat: 34.82946874516051},
  {lng: 115.10000085210717, lat: 34.82923910769224},
  {lng: 115.09994695377657, lat: 34.82911688103432},
  {lng: 115.09992000461126, lat: 34.82907613877432},
  {lng: 115.09988407239086, lat: 34.82902798880448},
  {lng: 115.09946636032873, lat: 34.82894280032668},
  {lng: 115.09943042810833, lat: 34.82894280032668},


  {lng: 115.0982671224729, lat: 34.82896502341642},
  {lng: 115.09707686767219, lat: 34.82900206188587},
  {lng: 115.09562610427358, lat: 34.82896502341642},
  {lng: 115.09539703636852, lat: 34.82897983880621},
  {lng: 115.09527127359713, lat: 34.828987246500105},
  {lng: 115.09514101929818, lat: 34.82897243111165},
  {lng: 115.09489847681049, lat: 34.8289576157205},
  {lng: 115.09465144279525, lat: 34.8289576157205},
  {lng: 115.09296711996404, lat: 34.82881686937051},
  {lng: 115.09280093344469, lat: 34.82877983081701},
  {lng: 115.09264372998045, lat: 34.82878723852906},
  {lng: 115.09241017054785, lat: 34.82880205395112},
  {lng: 115.0908111867401, lat: 34.82877612696074},
  {lng: 115.0906135595279, lat: 34.82881686937051},
  {lng: 115.0902901695443, lat: 34.8288539079072},
  {lng: 115.08977813540362, lat: 34.82887242716925},
  {lng: 115.08530457396392, lat: 34.828635380297705},
  {lng: 115.08483296357119, lat: 34.82859093393263},
  {lng: 115.0845275396978, lat: 34.82855389527659},
  {lng: 115.08423559040705, lat: 34.82850944886714},
  {lng: 115.08408287847035, lat: 34.82846870630387},
  {lng: 115.08363821724292, lat: 34.828250177662845},
  {lng: 115.08310821699203, lat: 34.82812424563783},
  {lng: 115.0763978748325, lat: 34.82761310777643},
  {lng: 115.07592626443976, lat: 34.82761681168549},
  {lng: 115.07562533209392, lat: 34.827598292138504},
  {lng: 115.07490219615838, lat: 34.82752050999524},
  {lng: 115.07482584019003, lat: 34.82751310216821},
  {lng: 115.07459228075744, lat: 34.82736864940665},
  {lng: 115.0741027042545, lat: 34.82709826278112},
  {lng: 115.07405329745146, lat: 34.82707233524941},
  {lng: 115.07345143275977, lat: 34.82674638843378},
  {lng: 115.0722566864315, lat: 34.826757500278475},
  {lng: 115.06941354949241, lat: 34.82704270377451},
  {lng: 115.06528583567408, lat: 34.82743531994377},
  {lng: 115.06318829230827, lat: 34.827631627320045},
  {lng: 115.06030922314879, lat: 34.82790571607437},
  {lng: 115.05919083278887, lat: 34.82801312898376},
  {lng: 115.05795566271264, lat: 34.82812054175179},
  {lng: 115.05775803550046, lat: 34.82815017283533},
  {lng: 115.05610964488964, lat: 34.828294624213505},
  {lng: 115.05603778044885, lat: 34.82827980869931},
  {lng: 115.05592100073255, lat: 34.82827980869931},
  {lng: 115.0558626108744, lat: 34.828294624213505},
  {lng: 115.05581320407136, lat: 34.828327959110624},
  {lng: 115.05324854184036, lat: 34.828546487543356},
  {lng: 115.05315871128937, lat: 34.828561303009145},
  {lng: 115.05313176212407, lat: 34.82857611847224},
  {lng: 115.05150133762345, lat: 34.82873538453061},
  {lng: 115.05146540540305, lat: 34.828716865237475},
  {lng: 115.05140701554491, lat: 34.82870945751903},
  {lng: 115.05131269346636, lat: 34.828716865237475},
  {lng: 115.05124082902556, lat: 34.82873168067233},
  {lng: 115.05014040477585, lat: 34.82884279634796},
  {lng: 115.04999218436669, lat: 34.828861315612535},
  {lng: 115.04982599784735, lat: 34.828876131021154},
  {lng: 115.04960591299741, lat: 34.82888353872446},
  {lng: 115.04945769258826, lat: 34.82886501946494},
  {lng: 115.04936786203726, lat: 34.82883909249453},
  {lng: 115.04926006537606, lat: 34.82881686937051},
  {lng: 115.04917023482507, lat: 34.82878353467313},
  {lng: 115.04886031942412, lat: 34.82870575365956},
  {lng: 115.04811472585084, lat: 34.82856871074103},
  {lng: 115.04786320030804, lat: 34.82853907980945},
  {lng: 115.04719845423067, lat: 34.82841685210302},
  {lng: 115.04688853882972, lat: 34.828398332737585},
  {lng: 115.04585997902079, lat: 34.82842425984801},
  {lng: 115.04555904667495, lat: 34.82844277920757},
  {lng: 115.04525811432912, lat: 34.828457594692175},
  {lng: 115.04498413114857, lat: 34.82847981791405},
  {lng: 115.04476404629862, lat: 34.82850204112987},
  {lng: 115.04466074116498, lat: 34.82851315273552},
  {lng: 115.04453946992113, lat: 34.828520560471766},
  {lng: 115.04445413089768, lat: 34.828527968207354},
  {lng: 115.04429692743344, lat: 34.82858723006779},
  {lng: 115.04340760497855, lat: 34.82881316551592},
  {lng: 115.04323243540411, lat: 34.82889094642709},
  {lng: 115.04303480819192, lat: 34.82894280032668},
  {lng: 115.04255870627162, lat: 34.82908354645947},
  {lng: 115.04236107905943, lat: 34.82914651175608},
  {lng: 115.04229370614618, lat: 34.829168734790336},
  {lng: 115.04222184170538, lat: 34.829172438628795},
  {lng: 115.04215446879213, lat: 34.82920947700409},
  {lng: 115.04210057046154, lat: 34.82922799618544},
  {lng: 115.04207362129624, lat: 34.829253923032276},


  {lng: 115.04186701102894, lat: 34.82930577670121},
  {lng: 115.04027701027628, lat: 34.82978727348212},
  {lng: 115.04010184070184, lat: 34.82983542300394},
  {lng: 115.03990421348963, lat: 34.829876164884844},
  {lng: 115.039711077805, lat: 34.8299094991359},
  {lng: 115.03941014545916, lat: 34.82993912956985},
  {lng: 115.03917209449901, lat: 34.829968759993044},
  {lng: 115.03893853506642, lat: 34.8300317246066},
  {lng: 115.03875887396443, lat: 34.830087281578216},
  {lng: 115.03826031440639, lat: 34.83026135984434},
  {lng: 115.03787404303709, lat: 34.83037617722087},
  {lng: 115.03778870401364, lat: 34.830442845300844},
  {lng: 115.03773480568304, lat: 34.83048729065723},
  {lng: 115.03758209374635, lat: 34.83054284731868},
  {lng: 115.03750124625044, lat: 34.830568773747736},
  {lng: 115.03739794111681, lat: 34.8305872926206},
  {lng: 115.03633794061503, lat: 34.830957669195136},
  {lng: 115.03434370238288, lat: 34.83165397260402},
  {lng: 115.03313548147196, lat: 34.8320798999517},
  {lng: 115.03310853230666, lat: 34.8320613814176},
  {lng: 115.03299175259036, lat: 34.83208360365802},
  {lng: 115.0328704813465, lat: 34.832157677749024},
  {lng: 115.03287497287407, lat: 34.832183603665},
  {lng: 115.03198565041919, lat: 34.83248360295058},
  {lng: 115.03197217583653, lat: 34.832472491885596},
  {lng: 115.03191827750594, lat: 34.83246508450811},
  {lng: 115.03183743001004, lat: 34.832487306638576},
  {lng: 115.03177454862434, lat: 34.832516936136436},
  {lng: 115.03173861640394, lat: 34.83256878773183},
  {lng: 115.03163980279784, lat: 34.832591009834054},
  {lng: 115.03134336197955, lat: 34.83267619450318},
  {lng: 115.03125802295611, lat: 34.83268730554042},
  {lng: 115.0311681924051, lat: 34.83270212025439},
  {lng: 115.02851369962312, lat: 34.8331502640805},
  {lng: 115.02706742775206, lat: 34.83336507595089},
  {lng: 115.02508666410256, lat: 34.833628034539544},
  {lng: 115.02385149402633, lat: 34.83372803265365},
  {lng: 115.02376615500289, lat: 34.833739143547646},
  {lng: 115.02368081597943, lat: 34.83375025444013},
  {lng: 115.0236493752866, lat: 34.83377988347938},
  {lng: 115.0236493752866, lat: 34.83379099436631},

  {lng: 115.02260284936746, lat: 34.833909510399536},
  {lng: 115.02051428905676, lat: 34.834124320270945},
  {lng: 115.01989894978243, lat: 34.8342020961194},
  {lng: 115.01933301731114, lat: 34.834324315160046},
  {lng: 115.01924318676015, lat: 34.83434653678413},
  {lng: 115.0191219155163, lat: 34.83436135119682},
  {lng: 115.01905454260306, lat: 34.83437246200459},
  {lng: 115.01878055942251, lat: 34.8344206088207},
  {lng: 115.01856945762766, lat: 34.834453941215216},
  {lng: 115.01842572874607, lat: 34.83445764481376},
  {lng: 115.01830894902977, lat: 34.83446505201036},
  {lng: 115.01818767778592, lat: 34.834468755608405},
  {lng: 115.01807089806962, lat: 34.83446505201036},
  {lng: 115.01792716918803, lat: 34.834453941215216},
  {lng: 115.01785979627478, lat: 34.83442431242078},
  {lng: 115.01779242336153, lat: 34.83439468361555},
  {lng: 115.01756335545649, lat: 34.834150245562},
  {lng: 115.01719954172495, lat: 34.83349099988782},
  {lng: 115.01664259230876, lat: 34.832442862371586},
  {lng: 115.01629674468742, lat: 34.83176508430039},
  {lng: 115.01620242260887, lat: 34.83157989805572},
  {lng: 115.01544335445294, lat: 34.830268767421884},
  {lng: 115.0152861509887, lat: 34.83012431953828},
  {lng: 115.015133439052, lat: 34.82994653717666},
]

let Weidongmaincanal = [
  {lng: 115.19021767447425, lat: 34.8199012045916},
  {lng: 115.1902041998916, lat: 34.819693765816545},
  {lng: 115.19018174225386, lat: 34.819604863323015},
  {lng: 115.1901143693406, lat: 34.81948632651436},
  {lng: 115.18936877576732, lat: 34.81830835762401},
  {lng: 115.18928343674388, lat: 34.8182453839657},
  {lng: 115.18918911466533, lat: 34.81817870592161},
  {lng: 115.18904987731128, lat: 34.81810832348263},
  {lng: 115.18843902956449, lat: 34.81779345393377},
  {lng: 115.1881605548564, lat: 34.817656392691944},
  {lng: 115.18646275744254, lat: 34.81717111995907},
  {lng: 115.1862247064824, lat: 34.817134076120496},
  {lng: 115.18597318093961, lat: 34.817030353283094},
  {lng: 115.1858564012233, lat: 34.81693033470792},
  {lng: 115.1858204690029, lat: 34.81681549841495},
  {lng: 115.18581597747536, lat: 34.816748819202964},
  {lng: 115.18581597747536, lat: 34.81669325315138},
  {lng: 115.18572614692437, lat: 34.81601534427682},

  {lng: 115.18526351958673, lat: 34.813199924785174},
  {lng: 115.18519165514593, lat: 34.81262942005897},
  {lng: 115.18485029905213, lat: 34.81176254161118},
  {lng: 115.18460775656445, lat: 34.811266119894405},
  {lng: 115.18447301073795, lat: 34.81094010996103},
  {lng: 115.18394301048706, lat: 34.809769427199065},
  {lng: 115.18388911215645, lat: 34.80956196268046},
  {lng: 115.18367351883406, lat: 34.8093248597281},
  {lng: 115.18352978995246, lat: 34.809198898504654},
  {lng: 115.18342199329126, lat: 34.80912480357658},
  {lng: 115.18323334913417, lat: 34.80909516558652},
  {lng: 115.18307165414238, lat: 34.809072937086924},
  {lng: 115.18277521332408, lat: 34.80906552758571},
  {lng: 115.18233504362419, lat: 34.80906552758571},
  {lng: 115.18159843310602, lat: 34.809058118083826},
  {lng: 115.18131097534283, lat: 34.80903588957414},
  {lng: 115.18107741591022, lat: 34.809050708581275},
  {lng: 115.18052046649404, lat: 34.809198898504654},
  {lng: 115.18034080539205, lat: 34.8092137174822},
  {lng: 115.18000843235335, lat: 34.80927299336551},
  {lng: 115.17973894070036, lat: 34.80935449763479},
  {lng: 115.17934368627597, lat: 34.809428592354465},
  {lng: 115.17906521156787, lat: 34.80953232484907},
  {lng: 115.17867894019858, lat: 34.80965087611011},
  {lng: 115.178445380766, lat: 34.80970274223271},
  {lng: 115.17831961799459, lat: 34.80973238000225},
  {lng: 115.1781309738375, lat: 34.809769427199065},
  {lng: 115.1779513127355, lat: 34.80978424607308},
  {lng: 115.17779860079881, lat: 34.80978424607308},
  {lng: 115.17766385497231, lat: 34.809762017761045},
  {lng: 115.17757402442132, lat: 34.80973238000225},
  {lng: 115.17743927859482, lat: 34.8096879233439},
  {lng: 115.17732249887852, lat: 34.80965087611011},


  {lng: 115.17384605655491, lat: 34.80852833695666},
  {lng: 115.17143859778817, lat: 34.807639186081694},
  {lng: 115.16920630859586, lat: 34.80676484494797},
  {lng: 115.16662368025469, lat: 34.80578305243353},
  {lng: 115.16392427219719, lat: 34.80470491951034},
  {lng: 115.16239266130269, lat: 34.80411583056053},
  {lng: 115.16209172895684, lat: 34.80398245134049},
  {lng: 115.16166054231205, lat: 34.80391576164879},
  {lng: 115.1614135082968, lat: 34.80391576164879},
  {lng: 115.16028613488179, lat: 34.80381943199798},
  {lng: 115.15887579523113, lat: 34.8037268072266},
  {lng: 115.15730825211621, lat: 34.80362306735797},
  {lng: 115.15425401338229, lat: 34.80340447220344},
  {lng: 115.15341858925801, lat: 34.803345192060696},
  {lng: 115.1513839272779, lat: 34.803171056392586},
  {lng: 115.1497714688875, lat: 34.80307472586351},
  {lng: 115.14811858674913, lat: 34.802956165056365},
  {lng: 115.1475301966401, lat: 34.8029376399147},
  {lng: 115.14579646700584, lat: 34.80278943863017},
  {lng: 115.14507333107031, lat: 34.80273386307914},
  {lng: 115.14445350026843, lat: 34.802700517730386},
  {lng: 115.14212688899758, lat: 34.802556021061775},
  {lng: 115.13801714128944, lat: 34.80231889776961},
  {lng: 115.13415442759653, lat: 34.80220774598945},
  {lng: 115.13164815522369, lat: 34.80198544197539},
  {lng: 115.13164815522369, lat: 34.80197803183116},
  {lng: 115.13096095150856, lat: 34.801981736903365},
  {lng: 115.13030518848628, lat: 34.80199285211894},
  {lng: 115.1293260354804, lat: 34.8019632115407},
  {lng: 115.12836934011227, lat: 34.80197062168627},
  {lng: 115.12746654307475, lat: 34.80193727602593},
  {lng: 115.12611908480977, lat: 34.80193727602593},
  {lng: 115.12512645722126, lat: 34.80192616080279},

  {lng: 115.12147035379564, lat: 34.80193357095172},
  {lng: 115.11972764110628, lat: 34.801952096321095},
  {lng: 115.11623323267247, lat: 34.80192616080279},
  {lng: 115.11365509585883, lat: 34.80195950646767},
  {lng: 115.11114433195844, lat: 34.802003967333},
  {lng: 115.10823831363366, lat: 34.80202619775659},
  {lng: 115.10535924447419, lat: 34.802022492686405},
  {lng: 115.1027317008575, lat: 34.80204101803562},
  {lng: 115.10089466608959, lat: 34.80214105484877},
  {lng: 115.09682534212939, lat: 34.80207806872143},
  {lng: 115.09249101804375, lat: 34.80213364471866},
  {lng: 115.08335075947973, lat: 34.80213734978379},
  {lng: 115.0821515216239, lat: 34.802218861174275},
  {lng: 115.07868855388294, lat: 34.802022492686405},
  {lng: 115.07587236610915, lat: 34.80188540497167},
  {lng: 115.072301601707, lat: 34.80172608652766},
  {lng: 115.06839397273859, lat: 34.801518601576575},
  {lng: 115.06481871680887, lat: 34.80135557731671},
  {lng: 115.0600666806611, lat: 34.80115920675352},
  {lng: 115.05940193458372, lat: 34.8011295658728},
  {lng: 115.05863837490023, lat: 34.80117773229851},
  {lng: 115.05836888324724, lat: 34.80119625783932},
  {lng: 115.0581577814524, lat: 34.80123701401428},
  {lng: 115.05615456016514, lat: 34.80147043535184},
  {lng: 115.05298354171492, lat: 34.80185576435445},
  {lng: 115.0498215063198, lat: 34.802252206719665},


  {lng: 115.04851896333032, lat: 34.80241893424243},
  {lng: 115.04702777618377, lat: 34.80257454628998},
  {lng: 115.04640794538189, lat: 34.80268569757101},
  {lng: 115.0456713348637, lat: 34.80275979834099},
  {lng: 115.045249131274, lat: 34.80281166883999},
  {lng: 115.04498862267612, lat: 34.80284130911035},
  {lng: 115.04467421574762, lat: 34.8028857694957},
  {lng: 115.04442269020483, lat: 34.80291540973918},
  {lng: 115.04417116466203, lat: 34.80296728013934},
  {lng: 115.04395557133964, lat: 34.803019150506586},
  {lng: 115.04193438394219, lat: 34.80481236294873},


  {lng: 115.03954489128564, lat: 34.80719831184951},
  {lng: 115.03649065255172, lat: 34.81010285121399},
  {lng: 115.03624811006404, lat: 34.810273267407005},
  {lng: 115.03600556757634, lat: 34.8103325425212},
  {lng: 115.03562827926214, lat: 34.81038440821084},
  {lng: 115.03517912650716, lat: 34.81043627386753},
  {lng: 115.03482878735826, lat: 34.810443683244365},
  {lng: 115.03462217709097, lat: 34.81042145511184},
  {lng: 115.03440658376857, lat: 34.81035477067794},
  {lng: 115.03436166849308, lat: 34.810347361293026},
]

let Shangqiumaincanal = [
  {lng: 115.03375980380139, lat: 34.80871357550378},
  {lng: 115.03344988840045, lat: 34.80888399459602},
  {lng: 115.03314446452706, lat: 34.80903588957414},
  {lng: 115.03289293898426, lat: 34.80912480357658},
  {lng: 115.03273573552002, lat: 34.80915814630254},
  {lng: 115.03240785400888, lat: 34.809247060171856},
  {lng: 115.03218327763138, lat: 34.80929892655093},
  {lng: 115.03193624361613, lat: 34.8093396786828},
  {lng: 115.03174759945904, lat: 34.809365611847035},
  {lng: 115.03154098919174, lat: 34.80938043079433},
  {lng: 115.03136132808974, lat: 34.80939154500304},
  {lng: 115.03096607366535, lat: 34.809439706556624},
  {lng: 115.03071903965012, lat: 34.80947304915401},
  {lng: 115.03040463272163, lat: 34.80950639173777},
  {lng: 115.03013963259617, lat: 34.809539734307926},


  {lng: 115.02272861213886, lat: 34.810028757106096},
  {lng: 115.01347606538609, lat: 34.81062150808679},
  {lng: 115.0129101329148, lat: 34.81062150808679},
  {lng: 115.01227233600272, lat: 34.81051777697577},
  {lng: 115.01191301379873, lat: 34.810473320745},
  {lng: 115.01126623383155, lat: 34.81039181759238},
  {lng: 115.00990080945638, lat: 34.81006580416845},
  {lng: 115.0092180972688, lat: 34.80991761581821},
  {lng: 115.0046726713883, lat: 34.80873209933538},
  {lng: 114.99781860034717, lat: 34.80695379234176},
  {lng: 114.9970460576086, lat: 34.80685746627405},
  {lng: 114.99648012513731, lat: 34.80676114009274},
  {lng: 114.99559978573753, lat: 34.80660553602138},
  {lng: 114.9931024964198, lat: 34.806368424485434},
  {lng: 114.98919486745139, lat: 34.80642770243395},
  {lng: 114.98407452604451, lat: 34.80697602141817},
  {lng: 114.9803824903985, lat: 34.80733909547644},
  {lng: 114.9801309648557, lat: 34.807413192024704},
  {lng: 114.97943926961302, lat: 34.80754656564216},
  {lng: 114.97754384498697, lat: 34.807857769902526},
  {lng: 114.9767713022484, lat: 34.80796891399383},
  {lng: 114.97129163863752, lat: 34.809058118083826},
  {lng: 114.96660248387543, lat: 34.810058394757334},
  {lng: 114.96583892419196, lat: 34.81016212645189},
  {lng: 114.96541672060226, lat: 34.81022881104311},


  {lng: 114.96363358416497, lat: 34.81058075659461},
  {lng: 114.96288349906413, lat: 34.81084378859527},
  {lng: 114.9601257011485, lat: 34.81175142772518},
  {lng: 114.95716578449313, lat: 34.81279242181607},
  {lng: 114.95508171570997, lat: 34.81348517565346},
  {lng: 114.95483917322228, lat: 34.81352963024448},
  {lng: 114.95439002046729, lat: 34.81359260754032},
  {lng: 114.95412502034185, lat: 34.813600016630744},
  {lng: 114.9539767999327, lat: 34.81360742572052},
  {lng: 114.9537791727205, lat: 34.81361113026515},
  {lng: 114.95053179830194, lat: 34.81381858449612},
  {lng: 114.9499434081929, lat: 34.81399269746172},
  {lng: 114.94944484863485, lat: 34.81415569649727},
  {lng: 114.94922027225736, lat: 34.81422608234382},


  {lng: 114.94851061090448, lat: 34.81451503413074},
  {lng: 114.94692959320692, lat: 34.81527074935408},
  {lng: 114.94204730276019, lat: 34.81752673995409},
  {lng: 114.9417867941623, lat: 34.817641575246604},
  {lng: 114.93843611461008, lat: 34.820138276831884},
  {lng: 114.9382115382326, lat: 34.820342010238306},
  {lng: 114.9376276396511, lat: 34.82076058818683},
  {lng: 114.93738060563585, lat: 34.82087912314512},
  {lng: 114.937169503841, lat: 34.82096061582909},
  {lng: 114.93656314762178, lat: 34.821075446291125},
  {lng: 114.93395806164284, lat: 34.82125695217586},
  {lng: 114.92993365295814, lat: 34.8215347665239},
  {lng: 114.92564873567555, lat: 34.82187555083283},
  {lng: 114.92178153045509, lat: 34.82222003787299},
  {lng: 114.91841288479267, lat: 34.822538594487234},
  {lng: 114.91501728996495, lat: 34.82287567048107},
  {lng: 114.91057067769057, lat: 34.82327941901503},
  {lng: 114.90637109943142, lat: 34.823672053288526},
  {lng: 114.90607016708557, lat: 34.82372391050419},
  {lng: 114.90579169237748, lat: 34.823801696265924},
  {lng: 114.90545482781124, lat: 34.82387577787484},
  {lng: 114.90529313281944, lat: 34.82392763496106},
  {lng: 114.90244550435281, lat: 34.82497958588262},
  {lng: 114.90016829988502, lat: 34.825790764130126},
  {lng: 114.89987635059428, lat: 34.825883363873956},
  {lng: 114.8994810961699, lat: 34.82599818741041},
  {lng: 114.89906338410775, lat: 34.82608708294058},
  {lng: 114.89467965321906, lat: 34.82686491469918},
  {lng: 114.89401041561412, lat: 34.82689454623864},
  {lng: 114.8887777860185, lat: 34.82731309058412},
  {lng: 114.88322625796684, lat: 34.827879788799194},
  {lng: 114.87922879844743, lat: 34.82903910033849},


  {lng: 114.87557718654938, lat: 34.830157653687955},
  {lng: 114.86991337030896, lat: 34.83199101094968},
  {lng: 114.86431243545425, lat: 34.833816919763294},
  {lng: 114.86315811287393, lat: 34.834150245562},
  {lng: 114.8623810786078, lat: 34.83434283318054},
  {lng: 114.8619229427977, lat: 34.834516902367696},
  {lng: 114.86089438298879, lat: 34.83505392198779},
  {lng: 114.86063836591845, lat: 34.83516132548762},
  {lng: 114.85750327968861, lat: 34.83697605289981},
  {lng: 114.85431878665574, lat: 34.838812960351625},
  {lng: 114.84977336077526, lat: 34.8414127062468},
  {lng: 114.84532225697332, lat: 34.842805128662164},
  {lng: 114.84273962863213, lat: 34.84367908378038},
  {lng: 114.83950572879621, lat: 34.84467893620093},
  {lng: 114.83661318505408, lat: 34.845638042374325},
  {lng: 114.83423267545264, lat: 34.84640457858061},
  {lng: 114.83237767457453, lat: 34.8471081561963},
  {lng: 114.8319060641818, lat: 34.847259980043425},
  {lng: 114.83160064030841, lat: 34.847356258434154},
  {lng: 114.83128623337991, lat: 34.84746364573588},
  {lng: 114.83104369089222, lat: 34.847526596847004},
  {lng: 114.83085953826267, lat: 34.84756362688967},
  {lng: 114.83042835161788, lat: 34.8476339839244},
  {lng: 114.83026216509853, lat: 34.84767471691677},
  {lng: 114.83014538538224, lat: 34.84771544988879},
  {lng: 114.82810623187459, lat: 34.848159808261876},


  {lng: 114.82455343358262, lat: 34.84899297368499},
  {lng: 114.82193487302104, lat: 34.849618767893304},
  {lng: 114.82174622886394, lat: 34.84967801443338},
  {lng: 114.8215710592895, lat: 34.8497668841628},
  {lng: 114.8212117370855, lat: 34.84997424648808},
  {lng: 114.82098266918047, lat: 34.8502593688243},
  {lng: 114.82049309267752, lat: 34.85156647534358},
  {lng: 114.81988673645829, lat: 34.853380837764},
  {lng: 114.81919504121561, lat: 34.855580235211086},
  {lng: 114.81914563441256, lat: 34.855769069599695},
  {lng: 114.81906478691666, lat: 34.855917174696465},
  {lng: 114.81892554956262, lat: 34.85606157690677},
  {lng: 114.81778470156495, lat: 34.85810169361935},
  {lng: 114.81634741274898, lat: 34.86050089440352},
  {lng: 114.81576800569505, lat: 34.861504242925015},
  {lng: 114.81556139542775, lat: 34.861855967342684},
  {lng: 114.81530986988496, lat: 34.86222620193203},
  {lng: 114.81432622535152, lat: 34.86325174296295},
  {lng: 114.80927774838545, lat: 34.86910115264814},
  {lng: 114.80446283085197, lat: 34.874468912304096},
  {lng: 114.80388791532559, lat: 34.87500196706198},
  {lng: 114.8033489320196, lat: 34.87534252799787},
  {lng: 114.8028997792646, lat: 34.87565347369593},
  {lng: 114.80228893151782, lat: 34.87600883875467},
  {lng: 114.80191164320364, lat: 34.876260554733335},
  {lng: 114.80151638877925, lat: 34.876482656421516},
  {lng: 114.80110316824465, lat: 34.876675144061444},
  {lng: 114.80056418493866, lat: 34.87689724461924},
  {lng: 114.79988147275108, lat: 34.87714895785267},
  {lng: 114.79932452333489, lat: 34.877297024097224},
  {lng: 114.7992346927839, lat: 34.877311830706866},
]

let WestBranchofduzhuangRiver = [
  {lng: 114.81831470181582, lat: 34.75457270725817},
  {lng: 114.81812605765873, lat: 34.75499162218612},
  {lng: 114.8176903794864, lat: 34.7570379722461},
  {lng: 114.817654447266, lat: 34.75707875044097},
  {lng: 114.81756012518744, lat: 34.75718254939077},
  {lng: 114.81687292147231, lat: 34.75754955176453},
  {lng: 114.81686393841721, lat: 34.75762740054126},
  {lng: 114.81654054843362, lat: 34.75849114628442},
  {lng: 114.81623063303267, lat: 34.75941790196245},
  {lng: 114.81629351441838, lat: 34.75955135391539},
  {lng: 114.81641927718978, lat: 34.76017041985002},
  {lng: 114.81652258232343, lat: 34.76050033930287},
  {lng: 114.81639232802448, lat: 34.76080430885711},
  {lng: 114.81603749734803, lat: 34.76105638031374},
  {lng: 114.81592970068684, lat: 34.76110827728192},
  {lng: 114.81592520915929, lat: 34.761111984206956},
  {lng: 114.8157455480573, lat: 34.7610971565058},
  {lng: 114.81553893779, lat: 34.76120095035751},
  {lng: 114.81518410711355, lat: 34.76127879565986},
  {lng: 114.8149730053187, lat: 34.76134922706009},
  {lng: 114.81486970018507, lat: 34.7614122445773},
  {lng: 114.81467207297287, lat: 34.76160500374037},
  {lng: 114.81452385256372, lat: 34.761838538271796},
  {lng: 114.81437563215458, lat: 34.76201646889587},
  {lng: 114.81422741174543, lat: 34.76239457018706},
  {lng: 114.81411961508424, lat: 34.76259844760905},
  {lng: 114.81362554705375, lat: 34.7630877513487},
  {lng: 114.81361656399865, lat: 34.76323973145999},
  {lng: 114.81350427580989, lat: 34.76346584768838},
  {lng: 114.8134054622038, lat: 34.76441849444235},
  {lng: 114.8133964791487, lat: 34.76493373456172},
  {lng: 114.8133695299834, lat: 34.765167259590164},
  {lng: 114.8134054622038, lat: 34.7654304377914},
  {lng: 114.8134863096997, lat: 34.765860417256},
  {lng: 114.81360308941599, lat: 34.76687234276617},
  {lng: 114.81367495385679, lat: 34.76711698221909},
  {lng: 114.81378275051799, lat: 34.767791590252784},
  {lng: 114.8137647844078, lat: 34.76907036493574},
  {lng: 114.81376029288025, lat: 34.76921492081594},
  {lng: 114.81386808954144, lat: 34.769570749586336},
  {lng: 114.81394893703734, lat: 34.769737543789525},
  {lng: 114.81397588620264, lat: 34.769848739735984},
  {lng: 114.81396241161998, lat: 34.76989692459917},
  {lng: 114.81364800469149, lat: 34.77017862015426},
  {lng: 114.8135581741405, lat: 34.77029722851876},
  {lng: 114.81347283511705, lat: 34.77043436922577},
  {lng: 114.813423428314, lat: 34.77055297721933},
  {lng: 114.81340097067626, lat: 34.77075683430636},
  {lng: 114.81340995373135, lat: 34.77087544183225},
  {lng: 114.81342791984154, lat: 34.77118678576926},
  {lng: 114.81350427580989, lat: 34.77150183497511},
  {lng: 114.81384114037614, lat: 34.772495158642506},
  {lng: 114.81378724204554, lat: 34.772954752077354},
  {lng: 114.81377825899044, lat: 34.77306223726604},
  {lng: 114.81371537760474, lat: 34.77330315183088},
  {lng: 114.81365249621905, lat: 34.773540359324315},
  {lng: 114.81360758094354, lat: 34.773677494592576},
  {lng: 114.81357164872314, lat: 34.77384428041967},
  {lng: 114.81348181817215, lat: 34.77418897005099},
  {lng: 114.81305961458246, lat: 34.774748624314505},
  {lng: 114.81295181792126, lat: 34.77485610714518},
  {lng: 114.81286198737027, lat: 34.77493764575008},
  {lng: 114.81240385156018, lat: 34.77524897422081},
  {lng: 114.81219724129288, lat: 34.77534904383465},
  {lng: 114.81196817338784, lat: 34.77547505724825},
  {lng: 114.81184241061644, lat: 34.77556771404611},
  {lng: 114.81175258006544, lat: 34.775967990206276},
  {lng: 114.81155944438079, lat: 34.776557282098224},
  {lng: 114.8114067324441, lat: 34.77674629935259},
  {lng: 114.81136630869615, lat: 34.77680189257955},
  {lng: 114.8112540205074, lat: 34.77699090926842},
  {lng: 114.81114173231866, lat: 34.7771243325504},
  {lng: 114.81089020677587, lat: 34.77738747227396},
  {lng: 114.81077342705956, lat: 34.77756166289269},
  {lng: 114.81071503720142, lat: 34.77774326548303},
  {lng: 114.81052639304433, lat: 34.77822506631898},
  {lng: 114.81045003707597, lat: 34.778551206811805},
  {lng: 114.81035571499743, lat: 34.7790181784342},
  {lng: 114.81027935902908, lat: 34.7794295559869},
  {lng: 114.81030630819437, lat: 34.77968527612497},
  {lng: 114.81034673194233, lat: 34.78005217753463},
  {lng: 114.81044554554842, lat: 34.780737796967315},
  {lng: 114.81045902013108, lat: 34.780982394940864},
  {lng: 114.81022995222602, lat: 34.78209048871361},
  {lng: 114.81000986737608, lat: 34.78276126727753},
  {lng: 114.80946639254255, lat: 34.78430292532334},
  {lng: 114.80934062977114, lat: 34.7846290415686},
  {lng: 114.8089408838192, lat: 34.78551103125924},
  {lng: 114.80891842618146, lat: 34.785777849794734},
  {lng: 114.80884207021312, lat: 34.785985374720156},
  {lng: 114.80842435815097, lat: 34.78699705118596},
  {lng: 114.80832105301732, lat: 34.787297216519605},
  {lng: 114.80745867972774, lat: 34.789713322016944},

  {lng: 114.80662325560347, lat: 34.79211823952647},
  {lng: 114.80571147551083, lat: 34.794804696963936},
  {lng: 114.80563961107003, lat: 34.794971439686705},
  {lng: 114.8051500345671, lat: 34.79722428578263},
  {lng: 114.80466944111926, lat: 34.798206181172446},
  {lng: 114.80445384779686, lat: 34.798780491873906},
  {lng: 114.80430113586017, lat: 34.798991688987485},
  {lng: 114.80420681378162, lat: 34.79950671123841},
  {lng: 114.80348367784609, lat: 34.80101470735839},
  {lng: 114.80332647438185, lat: 34.80117402718986},
  {lng: 114.80313783022476, lat: 34.80171497127578},
  {lng: 114.8031692709176, lat: 34.80205954338063},

  {lng: 114.80320969466555, lat: 34.8023374230516},
  {lng: 114.80331749132675, lat: 34.80323033666171},
  {lng: 114.80311986411455, lat: 34.80413806040936},
  {lng: 114.80256740622592, lat: 34.807739216038414},
  {lng: 114.80249105025757, lat: 34.808906223147055},
  {lng: 114.80238325359637, lat: 34.8100991465102},
  {lng: 114.80229342304537, lat: 34.811658811949634},
  {lng: 114.80217664332908, lat: 34.81401122009578},
  {lng: 114.80203740597503, lat: 34.81687847317626},

  {lng: 114.80209579583318, lat: 34.81705257961649},
  {lng: 114.80215867721887, lat: 34.81722298130482},
  {lng: 114.80267969441466, lat: 34.81867508561018},
  {lng: 114.80344325409814, lat: 34.821327331900115},
  {lng: 114.80425172905713, lat: 34.82360908376806},
  {lng: 114.8052039328977, lat: 34.826372288779524},
  {lng: 114.805230882063, lat: 34.82652785518067},
  {lng: 114.8052398651181, lat: 34.82671305289061},
  {lng: 114.805320712614, lat: 34.826957513224244},
  {lng: 114.8053835939997, lat: 34.82710567064581},
  {lng: 114.8054464753854, lat: 34.82720197282559},
]

export {
  Huangcaihe, // 黄蔡河
  BeishaRiver, // 北沙河
  Dongfanghongmaincanal, // 东方红干渠
  Landongmaincanal, // 兰东干渠
  Landongmaincanal2, // 兰东干渠2
  Lancaitrunkcanal,// 兰开干渠
  Lanqimaincanal, //  兰杞干渠
  SimingRiver, // 四明河
  Helihe, // 贺李河
  Zhangxinhe, // 张新河
  Weidongmaincanal, // 魏东干渠
  Shangqiumaincanal, // 商丘干渠
  WestBranchofduzhuangRiver, // 杜庄河西支
  // box2_data
}

